module.exports = {
    locale: "English",
    111: 'This transaction follows the NFT111 protocol',
    menu: {
        market: 'Market',
	    collect: 'Collectibles',
	    hatching: 'Hatching',
        megg: 'MEgg',
        hatch: 'Hatch',
        my: 'My account',
        account: 'Mint',
        activity: 'Activity',
        legion: 'Legion',
        share: 'Shares',
        setting: 'Setting',
        logout: 'Log out',
        login: 'Login',
        discord: "Discord",
        foot: ' Term of use',
        app: ' Download',
        area: 'Service area',
        whitepaper: "Whitepaper",
        game: "Game",
        dragongame: "Dragon",
        selectRole: "Service area selection",
        createRole: "Create a new role",
        nowServe: "at present"
    },
    copy: {
        ok: 'Success',
        on: 'Fail',
    },
    element: {
        all: 'All',
        select_gun: 'Please select equipment type',
        online1: "Not available online",
        online2: "The game online cannot be presented"
    },
    messageBox: {
        tip: 'warm reminder',
        tip2: 'Tips',
        warntip: 'warm reminder',
        ok: 'OK',
        close: 'Close',
        confirm: 'confirm',
        cancel: 'Cancel',
        submit: 'Sign in',
        save: 'Save',
        logoutTip: 'Are you sure you want to log out',
        emailTip: 'Please complete the mailbox binding first',
        metamaskTip: `<h3>Please install MetaMask first</h3><p>MetaMask is available on <a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn" target="_blank">Chrome</a>, <a href="https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/" target="_blank">Firefox</a> and Open. You can download it here.</p>`,
        addressTip: `<h3>Please link to account first</h3><p>You need to connect to a valid account before you can continue.</p>`,
        bscTip: '<h3>Please link to BSC first</h3><p>You need to switch to Binance Chain before logging in.</p>',
        network: 'Switch To BSC Network',
        metamaskErr: 'MetaMask Error',
        connect: 'Connect account',
        loginTip: `Please Log in first,This operation can only be performed after logging in.`,
        accountTip: `It is detected that the current MetaMask account ({address1}) is not the login account ({address2}), please switch the MetaMask account, otherwise subsequent use will be affected.`,
        accountTitle: 'Account exception',
        accountBtn: 'New account login',
        accountBtn1: 'Switch MetaMask',
        createRole: `You haven't created a role yet, would you like to create it no`,
        createRoleTitle: "Create roles",
        selectServe: "Service area selection",
        selectTip: "Please select a regional server",
        depositTip2: `1.The game characters in each area of this game are independent of
        each other, please confirm and deposit.`,
        depositTip2: `2.Please do not close the window before the transaction is completed,
        otherwise your account may suffer losses`,
        noServers: "No area",
        newemail: "Confirm new email",
        gametitle: "Scan code to download",
        active: "Whether to activate the MDragon {name} ?",
        activetip: "Payment successful ! It will take a few minutes to confirm on-chain, please be patient...",
        activetip2: 'Congratulations, you have activated {name} !',
        forgetip: 'Whether to reset your login password to <span class="t-bule">123456</span>',
        resetbtn: 'Immediate reset',
        tibi: " please wait patiently after each withdrawal, it will be transferred to your wallet within 24 hours.",
        nobalance: 'Please top up 1 MDV first to activate your account ',
        buysuc: "Successful purchase",
        noopen: "Not yet open",
	    mdvTip: 'Top up MDV',
	    mdvCofirm: ' Top up',
    },
    page: {
        page: 'Page',
        of: 'of',
        next: 'Next',
        prev: 'Back',
        start: 'Get Started',
    },
    login: {
        metamask: 'Login with BSC Wallet',
        email: 'Login with email & password',
        remember: 'Remember me',
        forget: 'Forget password?',
        where: `Don't know where to start?`,
        start: 'Getting started',
        content: 'Enter the email adress linked with your account. we will email you a link to reset your password.',
        to: 'Email me',
        tiptitle: 'Five steps for new users ',
        tip1: '1. Login with BSC Wallet',
        tip2: '2. Activate your email account',
        tip3: '3. Deposit HFH',
        tip4: '4. Activate the account number ',
        tip5: '5. Download and install the game APP',
        tip6: 'Log in with your email account and open the Challenge MWars!',
        guideText: 'MWars New User Registration Guide',
        guideUrl: 'https://metadragon.gitbook.io/en/guide/mwars'
    },
    forget: {
        title: 'An email has been sent',
        content: 'An email has been sent to <span class="c-w">{email}.</span>  Follow the instruction in the email to reset your password.',
    },
    home: {
        time: '活動時間：2021-12-12 ~ 2021-12-22',
        zige: '活動參與資格：東風湖哇撒奮達科技窗打開寫邏輯',
        guize: '活動規則：大概是驕傲的好時機肯定會就開始帶回家客戶端接電話測試接口',
        btn: '購買',
        area: "Area"
    },
    summon: {
        summon1: "Summon",
        summon10: "Summon 10 times",
        summonnow: "Summon now",
        blance: 'Balance'
    },
    game: {
        qr: 'Show QR',
        title: 'Login with QR Code',
        content: 'DO NOT share this QR code to anyone',
        tipLogin: 'You have not logged in your account, please log in first !',
    },
    presale: {
        content0: `Before starting the game, you need to activate at least one hero character, which will cost {price} {coin1} to activate.`,
        content1: `Before starting the game, you need to activate at least one hero character, which will cost {price} {coin1} to activate.`,
        content2: `It will take about 5-10 minutes to activate the payment transaction confirmation, please wait patiently`,
        content3: `The character activation is successful, congratulations on getting a chance to draw the equipment blind box, come and try it`,
        btn0: 'Activation',
        btn1: 'Activation',
        btn2: 'Activating...',
        btn3: 'Come try your luck',
        tip0: 'Activating payment',
        tip1: 'It takes about 5~10 minutes to confirm the blockchain after METAMASK payment...',
        name: {
            0: 'Honir',
            1: 'Ares',
            2: 'Lewelyn',
            3: 'Venus',
            4: 'Vali',
        },
    },
    market: {
        hero: "Hero",
        nodata: "No data",
        filter: 'Filter',
        clear: 'Clear filter',
        camp: 'Camp',
        campList: {
            element1: "All",
            element2: "Gods",
            element3: "Chita",
            element4: "Auspiciou",
            element5: "Holy Beast",
            element6: "Spirit animal",
        },
        skillTitle: 'Skill attribute',
        skill: {
            // 火、冰、風、光、暗
            element1: "All",
            element2: "Fire",
            element3: "Ice",
            element4: "Wind",
            element5: "Light",
            element6: "Dark",
        },
        level: 'Level',
        dragon: 'MDragon',
        Amount: 'Amount',
        buydragon: "Buy MDragon",
        buydragontip: "Don't close this before complete the transaction",
        equip: 'Cards',
        addDragonBtn: "Add MDragon",
        addDragonBox: {
            title: "Add MDragon",
            id: "Select generation level",
            addQuantity: "Generated quantity",
            totalQuantity: 'Total quantity can be generated',
            remainQuantity: 'Remaining Generatible Quantity',
            successTxs: 'Successful trade',
            failedTxs: 'Transaction failed',
            idPlaceholder: "Enter generated quantity",
            btnCancel: "Cancel",
            btnConfirm: "Confirm",
            notes: "Once MDragon is successfully added to your account, please don't transfer it privately using a wallet. If you transfer MDragon to someone else using a wallet, they won't be able to use it, and they must to transfer it back to your wallet. If you intend to transfer the NFT, please transfer through a public auction in the Marketplace.",
            success: "Holy Dragon Added Successfully",
            costUsdt: 'Expend USDT',
            costHfh: 'Expend HFH',
            connectWallet: 'Please log out and connect to the wallet'
        },
        pet: 'Pet',
        lingshi: 'Talent stone',
        pwd: 'Payment password',
        input: "Please enter your fund password",
        star: "Star",
        sort: {
            IdL: 'Lowest ID',
            IdH: 'Highest ID',
            PriceL: 'Lowest price',
            PriceH: 'Highest price',
            Latest: 'Latest',
            LevelH: 'Highest Level',
            LevelL: 'Lowest Level',
            stageH: 'Highest Stage',
            stageL: 'Lowest Stage',
            strengthH: 'Highest Strength',
            strengthL: 'Lowest Strength',
            starH: 'Highest Star',
            starL: 'Lowest Star',
            tokenup: 'Tokenid ascending',
            tokendown: 'Tokenid descending',
        },
        detail: {
            about: 'About',
            attr: 'Essential attributes',
            type: 'Asset type',
            role: 'Role restriction',
            part: 'Part type',
            strength: 'Enhanced level',
            star: 'Star rating',
            stage: 'Stage level',
            owner: 'Owner',
            address: 'Location address',
        },
        attr: {
            hp: 'Life',
            att: 'Attack',
            defense: 'Defense',
            hp_1: 'Life',
            att_1: 'Attack',
            defense_1: 'Defense',
            crit: 'Crit strength',
            crit_res: 'Crit resistance',
            super_crit: 'Superb strike',
            vampire: 'Attack vampire',
            speed_pct: 'Movement speed',
            super_att: 'Final damage',
            super_defense: 'Final injury free',
        },
        profession: 'Profession',
        professionList: {
            element1: "All",
            element2: "Warrior",
            element3: "Master",
            element4: "Tank",
            element5: "Auxiliary",
        },
        sorttitle: "Sort",
        starrating: "Star rating",
        starrateList: {
            star0: "All",
            star1: "1 Star",
            star2: "2 Star",
            star3: "3 Star",
            star4: "4 Star",
            star5: "5 Star",
            star6: "6 Star",
            star7: "7 Star",
        },
        grade: "Grade",
        gradeList: {
            grade0: "All"
        },
        status: {
            withdrawCoin: 'Withdraw',
            withdraw: 'To wallet',
            withdrawtitle: 'Withdraw to wallet',
            claim: 'To wallet',
            claimtitle: 'Transfer to wallet',
            depositCoin: 'Deposit',
            deposit: 'To game',
            depositTitle: 'Transfer game',
            sell: 'Sell',
            cancel: 'Cancel',
            buy: 'Buy',
            use: 'Equipped',
            claiming: 'Claiming',
            confirm: 'Confirming',
            selling: 'Selling',
            auction: 'Auction',
            auctioning: 'Auctioning',
            auctioncard: "Auction Card",
            auctionadd: 'Participate in bidding',
            minPrice: "Starting Price",
            minAdd: "Minimum bid",
            add: 'Bid',
            nftErrorTransferredOut: 'NFT has been transferred out, please return it before proceeding.',
            old: 'Old'
        },
        price: "Price",
        history: {
            title: 'Sale history',
            buyer: 'Buyer',
            seller: 'Seller',
        },
        fee: "Poundage",
        sort: {
            latest: "New releases",
            lowP: "Lowest price",
            hightP: "Highest price",
        }
    },
    dragon: {
        back: "Back",
        info: 'Basic Attritubes',
        name: "Name",
        refined: "Refined",
        level: "Level",
        hp: "Squad HP",
        atk: "Squad ATK",
        specialAttributes: "Special Attributes",
        allatk: "All squad ATK",
        allacc: "All squad ACC",
        allspd: "All squad Free",
        effect: "Refined Effect",
        refinelv: "Refining level",
        addatk: "Skill damage increased",
        totalSkill: "Total damage",
        dragonskill: "MDragon Skill",
        bone: "Bone eroding inflammation",
        skill: "Passive skill",
        ji: "level",
        lv4ji: "Lv.4 Skill to activate",
        lv8ji: "Lv.8 Skill to activate",
        lv12ji: "Lv.12 Skill to activate",
        suc: "You have activated the MDragon",
        notactive: "Not activated"
    },
    card: {
        hero: "Hero",
        buy: "Buy",
        unsell: "Unsell",
        info: "Basic information",
        name: "Name",
        grade: "Grade",
        camp: "Camp",
        profession: "Profession",
        power: "Combat power",
        level: "Level",
        star: "Star",
        num: "NUM",
        currentPrice: "Current bid",
        totalamount: "Total transaction amount",
        attributes: "Basic Attributes",
        blood: "Blood",
        attack: "Attack",
        defense: "Defense",
        speed: "Speed",
        sattributes: "Special Attributes",
        rate: "Critical strike rate",
        control: "Control",
        riot: "Riot resistance",
        free: "Injury free",
        heal: "Heal",
        damage: "Damage",
        spelldamage: "Spell damage",
        tospelldamage: "Immunity to spell damage",
        crit: "Crit",
        harm: "Harm",
        hit: "Anti-control hit",
        dodge: "Dodge",
        treatment: "Treatment",
        pdamage: "Physical damage",
        damagefree: "Physical damage-free",
        heroSkill: "Hero Skill",
        history: "Trading history",
        token: "Card token",
        time: "Transaction hour",
        from: "Current winner",
        to: "The trader",
        price: "Transaction Price",
        rice: "Putin Rice",
        type: "Type",
        zhudong: "Active skills",
        beidong: "Passive skill",
        status: {
            operate: "Do not operate",
            synchronizing: "Synchronizing game data",
            chain: "In the chain",
            intogame: "Put it in the game",
            confirmation: "Sell confirmation",
            updategame: "Update game data",
            chainfail: "Chain on the failure",
            syncfail: "Synchronization failure",
            gameAssets: "Game Assets",
            walletAssets: "Wallet Assets",
            auctioned: "Contract Assets",
        },
        upgrade: "Skills upgrading",
        timeout: "In auction",
        modify: "Modify price",
        current_price: "Current price",
        new_price: "Please enter a new price",
        modifySuccess: "Modified successfully"
    },
    hero: {
        name: {
            "21": "Frey",
            "22": "Njord",
            "23": "Freya",
            "31": "Njord",
            "32": "Freya",
            "33": "Gevjon",
            "41": "Frey",
            "42": "Njord",
            "43": "Freya",
            "51": "Sol",
            "52": "Verdandi",
            "53": "Gevjon",
            "54": "Leinath",
            "55": "Arly",
            "61": "Amy",
            "62": "Belnus",
            "63": "Lewelyn",
            "64": "Andvari",
            "65": "Balder",
            "11001": "Amy",
            "11002": "Nanna",
            "12001": "Shaarkot",
            "12002": "Gasarno",
            "12003": "Honir",
            "12004": "Zudak",
            "13001": "Tyr",
            "13002": "Momus",
            "13003": "Nashgar",
            "13004": "Prometheus",
            "13005": "Rudolph",
            "14001": "Sol",
            "14002": "Phoenix",
            "14003": "Scarlett",
            "14004": "Hera",
            "14005": "Gormek",
            "15001": "Elena",
            "15002": "Hephaistos",
            "15003": "Hestia",
            "15004": "Loki",
            "15005": "Candy Witch",
            "15006": "Santa Claus",
            "21001": "Magni",
            "21002": "Belnus",
            "22001": "Jelanda",
            "22002": "Scamander",
            "22003": "Olaf",
            "23001": "Verdandi",
            "23002": "Graymane",
            "23003": "Ares",
            "23004": "Ulmer",
            "23005": "Athena",
            "24001": "Boril",
            "24002": "Sharleen",
            "24003": "Frigga",
            "24004": "Poseidon",
            "24005": "Mr.Snowman",
            "25001": "Gaea",
            "25002": "Zeus",
            "25003": "Thorne",
            "25004": "Njord",
            "31001": "Lewelyn",
            "31002": "Satyrus",
            "32001": "Pedro",
            "32002": "Brogan",
            "32003": "Cupid",
            "33001": "Carver",
            "33002": "Isshtak",
            "33003": "Brienne",
            "33004": "Sif",
            "33005": "Budle",
            "33006": "Gevjon",
            "34001": "Little John",
            "34002": "Sindri",
            "34003": "Hermes",
            "34004": "Kashhrek",
            "34005": "Melia",
            "35001": "Artemis",
            "35002": "Kadilen",
            "35003": "Frey",
            "35004": "Vidar",
            "41001": "Forseti",
            "41002": "Lucio",
            "42001": "Idon",
            "42002": "Modi",
            "42003": "Nash",
            "43001": "Dawa",
            "43002": "Apollo",
            "43003": "Skuld",
            "43004": "Venus",
            "44001": "Goku",
            "44002": "Phoebe",
            "44003": "Leinath",
            "44004": "Balder",
            "45001": "Leonidas",
            "45002": "Freya",
            "45003": "Odin",
            "45004": "Thor",
            "51001": "Nott",
            "51002": "Badar",
            "52001": "Andvari",
            "52002": "Silthus",
            "52003": "Vali",
            "53001": "Balthazar",
            "53002": "Urd",
            "53003": "Pandora",
            "53004": "Medusa",
            "53005": "Renfeld",
            "54001": "Arly",
            "54002": "Sabina",
            "54003": "Rigard",
            "54004": "Erebus",
            "54005": "Raymond",
            "54006": "Pumpkin Ghost",
            "55001": "Domitia",
            "55002": "Hades",
            "55003": "Hecate",
            "55004": "Hela",
            "56": "GOD EMISSARY",
            "1503": "Man in Black",
            "11901": "Trainer Hero",
            "12901": "Trainer Hero",
            "13901": "Trainer Hero",
            "14901": "Trainer Hero",
            "15901": "Trainer Hero",
            "21901": "Trainer Hero",
            "22901": "Trainer Hero",
            "23901": "Trainer Hero",
            "24901": "Trainer Hero",
            "25901": "Trainer Hero",
            "31901": "Trainer Hero",
            "32901": "Trainer Hero",
            "33901": "Trainer Hero",
            "34901": "Trainer Hero",
            "35901": "Trainer Hero",
            "41901": "Trainer Hero",
            "42901": "Trainer Hero",
            "43901": "Trainer Hero",
            "44901": "Trainer Hero",
            "45901": "Trainer Hero",
            "51901": "Trainer Hero",
            "52901": "Trainer Hero",
            "53901": "Trainer Hero",
            "54901": "Trainer Hero",
            "55901": "Trainer Hero",
        },
        status: {
            0: 'In the team',
            1: 'Sell',
            2: 'Locked',
            3: 'On-chain confirmation',
            4: 'Sell confirmation',
            5: 'Cancel',
            51: 'Buy',
            6: 'Operation prohibited',
        },
    },
    account: {
        setAccount: "Let' s complete setting up your account",
        setEmail: 'Set up email & password',
        wallet: 'Wallets',
        address: 'Address',
        see: 'More',
        tip: 'game online cannot {type}',
        captip: 'Slide right to fill the puzzle',
        Activate: "Activate",
        paimai: "Auction",
        paimaiIng: "On the block",
        receive: 'Receive',
        Activateing: 'The activation of',
        hasActivate: "Has been activated",
        forsale: "For sale",
        salePrice: "Selling price",
        salecard: "Sell cards",
        saleprice: "Sale amount",
        p_saleprice: "Please enter auction price",
        saletip: `When the transaction is completed, the platform will charge {fee} of the sale amount as a handling fee`,
        saletip1: 'Current transfer network is BSC network',
        saletip2: `After authorization, your NFT will be circulated in the market according to the NFT111 protocol rules:
        <br />1. Anyone can bid no less than 1.11 times the current price to force the transfer of NFT ownership. 
        <br />2. For each transaction, 95% goes to the holder, 3% goes to the copyright owner, 1% is the transaction fee, and 1% goes to the historical traders.
        <br />3. Timeout lock, after no transaction exceeds a certain time, the auction status will be automatically locked.`,
        sell: "Sell",
        shelf: "Take off the shelf",
        shelfor: "Whether the shelves",
        shelgsuc: "successfully stocked",
        auctionsuc: "The shooting has started successfully, please wait for the confirmation on the chain!",
        reAuctionsuc: "The bid has been successful, please wait for the confirmation on the chain!",
        depositsuc: "It has been successfully deposited, please wait for the confirmation on the chain!",
        depositsuc2: "The game has been successfully entered, please wait for the confirmation on the chain!",
        claimsuc: 'received successfully',
        claimsuc2: 'has been successfully transferred to the wallet',
        withdrawsuc: 'It has been submitted successfully, please wait for the confirmation on the chain!',
        reAuctionfail: "NFT locked, please wait for on-chain confirmation!",
        activetips: 'Please activate the account first',
        hasActivateTip: 'Account has been activated',
        activetip_p: `Do you want to spend <span style='color:#56A1FF;'>{type}</span> to activate your account`,
        Activateaccount: "Activate account",
        hasactive: 'Account activated'
    },
    activity: {
        title: 'Activities',
        view: 'View full activities',
        more: 'MORE',
        tip: {
            1: `You have recharged <span style="color:#67C23A">{amount} MDS</span> to your account successfully`,
            2: `You have extracted <span style="color:#67C23A">{amount2} MDS</span> to your account successfully`,
            3: `You buy Hero <span style="color:#67C23A">{name}</span> for <span style="color:#67C23A">{amount} BNB</span>`,
            4: `You sell Hero <span style="color:#67C23A">{name}</span> for <span style="color:#67C23A">{amount} BNB</span>`,
            5: `You have cancelled the sale of Hero <span style="color:#67C23A">{name}</span>`,
            6: `You have successfully sold a Hero <span style="color:#67C23A">{name}</span> by <span style="color:#67C23A">{amount} BNB</span>`,
            7: `Your withdrawal request has been submitted. Amount: <span style="color:#67C23A">{amount7} MDS</span>`,
            8: `Your withdrawal request has been rejected. Amount<span style="color:#67C23A">{amount8} MDS</span>`,
            9: `You have activated the MDragon <span style="color:#67C23A">{two_address}</span>`,
            11: `The Hero <span style="color:#67C23A">{name}</span> you summoned is chained`,
            12: `The Dragon <span style="color:#67C23A">{name}#{assets_id}</span> you activated is chained`,
            13: `You consume Hero <span style="color:#67C23A">{name}#{assets_id}</span> in the game`,
            14: `Your withdrawal application has been submitted, Amount:<span style="color:#67C23A">{amount14} ENFT</span>`,
            15: `Your withdrawal application has been rejected, Amount:<span style="color:#67C23A">{amount15} ENFT</span>`,
            16: `You have recharged your account successfully <span style="color:#67C23A">{amount} ENFT</span>`,
            17: `You have successfully extracted <span style="color:#67C23A">{amount17} ENFT</span>`,
            18: `You have received <span style="color:#67C23A">{amount}</span> call tickets`,
            19: `You have claimed Hero <span style="color:#67C23A">{name}#{assets_id}</span> from the game to your wallet，poundage：<span style="color:#67C23A">{fee}</span>MDS`,
            20: `You have deposited Hero <span style="color:#67C23A">{name}#{assets_id}</span> into the game from your wallet`,
            21: `You have to receive <span style="color:#67C23A">{name}#{assets_id}</span>，Please wait for on-chain confirmation`,
            22: `You have successfully recharged!`,
            23: `You have to receive <span style="color:#67C23A">{name}#{assets_id}</span>`,
            24: `You have saved dragon <span style="color:#67C23A">{name}#{assets_id}</span> from your wallet to the game`,
            25: `You have received <span style="color:#67C23A">{name}#{assets_id}</span>, please wait for confirmation on the chain`,
            26: `You have claimed dragon <span style="color:#67C23A">{name}#{assets_id}</span> from the game to your wallet，poundage：<span style="color:#67C23A">{fee}</span>MDS`,
            30: `You started an auction for card <span style="color:#67C23A">{name}#{assets_id}</span> for <span style="color:#67C23A">{amount} BNB</span>`,
            31: `Your card <span style="color:#67C23A">{name}#{assets_id}</span> has no bids, it has been unlocked from the 111 protocol, please submit it yourself`,
            32: `Your auctioned card <span style="color:#67C23A">{name}#{assets_id}</span> sells for <span style="color:#67C23A">{two_amount} BNB</span> and you get paid: <span style="color:#67C23A">{amount} BNB</span>`,
            33: `You have successfully bid on card <span style="color:#67C23A">{name}#{assets_id}</span>, bid: <span style="color:#67C23A">{amount} BNB</span>`,
            34: `Your bidding card <span style="color:#67C23A">{name}#{assets_id}</span> has a new bid and gets bonus: <span style="color:#67C23A">{amount} BNB</span>`,
            35: `Congratulations! You successfully bought card <span style="color:#67C23A">{name}#{assets_id}</span>, final bid: <span style="color:#67C23A">{amount} BNB</span>`,
            36: `The income was successfully received, the amount: <span style="color:#67C23A">{amount} BNB</span>`,
            37: `You have proposed card <span style="color:#67C23A">{name}#{assets_id}</span> from the 111 protocol contract to your wallet`,

            50: `You have recharged <span style="color:#67C23A">{amount} HFH</span> to your account successfully`,
            54: `You have extracted <span style="color:#67C23A">{amount2} HFH</span> to your account successfully`,
            51: `Your withdrawal request has been submitted. Amount: <span style="color:#67C23A">{amount7} HFH</span>`,
            53: `Your withdrawal request has been rejected. Amount<span style="color:#67C23A">{amount8} HFH</span>`,

            52: `You have activated the MDragon <span style="color:#67C23A">{two_address}</span>`,

            55: `You have activated the account, consumed META：<span style="color:#67C23A">{amount}</span>`,
            56: `You have activated the account, consumed MDV：<span style="color:#67C23A">{amount}</span>`,
            57: `You have recharged <span style="color:#67C23A">{amount} MDV</span> to your account successfully`,

            60: `You adopted a dragon with <span style="color:#67C23A">{amount} MDS</span>`,
            62: `You have modified the auction price of card <span style="color:#67C23A">{name}#{assets_id}</span> to <span style="color:#67C23A">{amount} BNB</span>`,
	        63: `You have add the MDragon<span style="color:#67C23A">{name}#{assets_id}</span>`,
            '1-3': `You have bought <a href="{origin}/gun/equip/{index_id}" target="_blank" rel="noopener noreferrer">EQUIP {nftname}#{tokenid}</a> from <span class="c-g">user#{two_userid}</span> with <span class="c-g">{amount} BNB</span>`,
            '1-4': `You are selling <a href="{origin}/gun/equip/{index_id}" target="_blank" rel="noopener noreferrer">EQUIP {nftname}#{tokenid}</a> for <span class="c-g">{amount} BNB</span>`,
            '1-5': `You cancelled the sale order for <a href="{origin}/gun/equip/{index_id}" target="_blank" rel="noopener noreferrer">EQUIP {nftname}#{tokenid}</a>`,
            '1-9': `You have successfully sold <a href="{origin}/gun/equip/{index_id}" target="_blank" rel="noopener noreferrer">EQUIP {nftname}#{tokenid}</a> to <span class="c-g">buyer#{two_userid}</span> for <span class="c-g">{amount} BNB</span> and received <span class="c-g">{two_amount} BNB</span> after deducting fee`,
            '1-6': `You are applying to claim <a href="{origin}/gun/equip/{index_id}" target="_blank" rel="noopener noreferrer">EQUIP {nftname}#{tokenid}</a> to address <span class="c-c">{two_address}</span> Transaction confirmation`,
            '1-6-1': `Your application to receive <a href="{origin}/gun/equip/{index_id}" target="_blank" rel="noopener noreferrer">EQUIP {nftname}#{tokenid}</a> from address <span class="c-c">{two_address}</span> is successful, and the handling fee is <span class="c-g">{fee} {coin}</span>`,
            '1-11': `You are applying to deposit <a href="{origin}/gun/equip/{index_id}" target="_blank" rel="noopener noreferrer">EQUIP {nftname}#{tokenid}</a> transaction confirmation to address <span class="c-c">{two_address}</span>`,
            '1-11-1': `Your application to deposit <a href="{origin}/gun/equip/{index_id}" target="_blank" rel="noopener noreferrer">EQUIP {nftname}#{tokenid}</a> to address <span class="c-c">{two_address}</span> is successful`,

            '2-3': `You have bought <a href="{origin}/gun/pet/{index_id}" target="_blank" rel="noopener noreferrer">PET {nftname}#{tokenid}</a> from <span class="c-g">user#{two_userid}</span> with <span class="c-g">{amount} BNB</span>`,
            '2-4': `You are selling <a href="{origin}/gun/pet/{index_id}" target="_blank" rel="noopener noreferrer">PET {nftname}#{tokenid}</a> for <span class="c-g">{amount} BNB</span>`,
            '2-5': `You cancelled the sale order for <a href="{origin}/gun/pet/{index_id}" target="_blank" rel="noopener noreferrer">PET {nftname}#{tokenid}</a>`,
            '2-9': `You have successfully sold <a href="{origin}/gun/pet/{index_id}" target="_blank" rel="noopener noreferrer">PET {nftname}#{tokenid}</a> to <span class="c-g">buyer#{two_userid}</span> for <span class="c-g">{amount} BNB</span> and received <span class="c-g">{two_amount} BNB</span> after deducting fee`,
            '2-6': `You are applying to claim <a href="{origin}/gun/pet/{index_id}" target="_blank" rel="noopener noreferrer">PET {nftname}#{tokenid}</a> to address <span class="c-c">{two_address}</span> Transaction confirmation`,
            '2-6-1': `Your application to receive <a href="{origin}/gun/pet/{index_id}" target="_blank" rel="noopener noreferrer">PET {nftname}#{tokenid}</a> from address <span class="c-c">{two_address}</span> is successful, and the handling fee is <span class="c-g">{fee} {coin}</span>`,
            '2-11': `You are requesting a deposit for <a href="{origin}/gun/pet/{index_id}" target="_blank" rel="noopener noreferrer">PET {nftname}#{tokenid}</a> transaction confirmation to address <span class="c-c">{two_address}</span>`,
            '2-11-1': `Your application to deposit <a href="{origin}/gun/pet/{index_id}" target="_blank" rel="noopener noreferrer">PET {nftname}#{tokenid}</a> to address <span class="c-c">{two_address}</span> is successful`,

            '3-3': `You have bought <a href="{origin}/gun/lingshi/{index_id}" target="_blank" rel="noopener noreferrer">Spirit Stone {nftname}#{tokenid}</a> from <span class="c-g">user#{two_userid}</span> with <span class="c-g">{amount} BNB</span>`,
            '3-4': `You are selling <a href="{origin}/gun/lingshi/{index_id}" target="_blank" rel="noopener noreferrer">Spirit Stone {nftname}#{tokenid}</a> for <span class="c-g">{amount} BNB</span>`,
            '3-5': `You cancelled the sale order for <a href="{origin}/gun/lingshi/{index_id}" target="_blank" rel="noopener noreferrer">Spirit Stone {nftname}#{tokenid}</a>`,
            '3-9': `You have successfully sold <a href="{origin}/gun/lingshi/{index_id}" target="_blank" rel="noopener noreferrer">Spirit Stone {nftname}#{tokenid}</a> to <span class="c-g">buyer#{two_userid}</span> for <span class="c-g">{amount} BNB</span> and received <span class="c-g">{two_amount} BNB</span> after deducting fee`,
            '3-6': `You are applying to claim <a href="{origin}/gun/lingshi/{index_id}" target="_blank" rel="noopener noreferrer">Spirit Stone {nftname}#{tokenid}</a> to address <span class="c-c">{two_address}</span> Transaction confirmation`,
            '3-6-1': `Your application to receive <a href="{origin}/gun/lingshi/{index_id}" target="_blank" rel="noopener noreferrer">Spirit Stone {nftname}#{tokenid}</a> from address <span class="c-c">{two_address}</span> is successful, and the handling fee is <span class="c-g">{fee} {coin}</span>`,
            '3-11': `You are requesting a deposit for <a href="{origin}/gun/lingshi/{index_id}" target="_blank" rel="noopener noreferrer">Spirit Stone {nftname}#{tokenid}</a> transaction confirmation to address <span class="c-c">{two_address}</span>`,
            '3-11-1': `Your application to deposit <a href="{origin}/gun/lingshi/{index_id}" target="_blank" rel="noopener noreferrer">Spirit Stone {nftname}#{tokenid}</a> to address <span class="c-c">{two_address}</span> is successful`,
        },
    },
    legion: {
        title: 'My Legion',
        infoTooltip: 'Share invitation links, invite friends to activate accounts and join your legion. When your legion spends MDS in MWars, including account activation, summoning, purchasing item in market, and you will earn points. Share the invitation link, invite friends to activate their account and join your legion. Your legion spends MDS on Mwars, including account activation, summons, and store scenes, and you will receive points.',
        ruleDescription: 'Rule description',
        level: 'Level',
        points: 'Points',
        receive: 'Receive',
        canReceive: 'Can receive',
        rules1: 'Can be claimed every 7 days',
        rules2: 'Final collection time: {finalTime}',
        viewDetails: 'View details',
        myLegion: 'My Legion',
        pointsRankingThisMonth: 'Points ranking this month',
        headerAccount: 'Account',
        headerLevel: 'Level',
        integralRules: 'Integral rule',
        integralRules1: 'condition',
        integralRules2: 'integral',
        integralRules3: 'warrior',
        integralRules4: 'Challenge Boss and Pass level 30',
        integralRules5: '6% self expenses',
        integralRules6: 'cavalier',
        integralRules7: 'Invite 6 or more Warriors as members',
        integralRules8: '8% self expenses + 2% invited member expenses',
        integralRules9: 'Nobleman',
        integralRules10: 'Invite 6 or more knights as members',
        integralRules11: '10% self expenses + 3% invited member expenses',
        integralRules12: '',
        integralRules13: '',
        getMdsBox: {
            title: 'Get MDS',
            amount: 'Amount',
            availablePoints: 'Available points',
            amountPlaceholder: 'Please enter the amount of MDS to receive',
            btnAll: 'All',
            btnConfirm: 'Confirm',
            success: 'Extraction successful'
        },
        levelName: {
            1: 'Soldier',
            2: 'Warrior',
            3: 'Cavalier',
            4: 'Nobleman',
        },
        history: {
            title: 'Points Details',
            myPoints: 'My Points',
            receivedMds: 'Received',
            tabPointAcquisition: 'Points acquisition',
            tabMdsCollection: 'MDS collection',
            titleMdsCollectionRecord: 'MDS collection record',
            more: 'More',
            typeMdsCollection: 'MDS collection',
            type: {
                1: 'Referral rewards',
                2: 'Spending rewards',
            }
        },
        ranks: {
            title: 'Ranking',
            subtitle: 'Set the bonus pool based on the monthly consumption data.',
            rewardsLink: 'Legion rewards this month',
            rewardsUrl: 'https://metadragon.gitbook.io/en/events/mwars-legion',
            headerRanking: 'Ranking',
            headerAccount: 'Account',
            headerIntegral: 'Integral',
            myOwn: 'Me',
        },
        rules: {
            title: 'Rule description',
            1: 'I. User level and points algorithm',
            2: '1. Warrior ☆',
            3: 'Conditions: Activated or inactive new members',
            4: 'Points: None',
            5: '2. Warrior ★',
            6: 'Conditions: Pass 30 lord challenge levels',
            7: 'Points: 6% on your own consumption',
            8: '3. Knight★ ★',
            9: 'Conditions: Directly recommend members with more than 6 warriors',
            10: 'Points: 8% of your own consumption + 2% of directly promoted member consumption',
            11: '4. Jazz★ ★ ★',
            12: 'Conditions: Directly recommend members with more than 6 knights',
            13: 'Points: 10% of your own consumption + 3% of directly promoted member consumption',
            14: 'The calculation method is as follows: [Recommendation relationship] A recommended B',
            15: '[Definition]: B’s spending is N, B’s own spending reward ratio is B1, recommendation reward ratio is B2, A’s own spending reward ratio is A1, recommendation reward ratio is A2, then: B points = N*B1, A points = N*A2, no reward for others.',
            16: 'For example: A (★ ★ ★) → B (★ ★), B costs 100MDS.',
            17: 'Then, B1=8%, A2=3%',
            18: 'The calculation is as follows:',
            19: 'B points increase 100*8%=8',
            20: 'A points increase 100*3%=3',
            21: 'II. Receive MDS',
            22: '1. After players in the legion make purchases, they and their direct superiors will immediately receive points.',
            23: '2. Redemption ratio: 1 point can receive 1MDS.',
            24: '3. Ranking list',
            25: 'The top 100 monthly points rankings are from the 1st to the end of each month.',
            26: 'Export the data table and manually distribute it via airdrop on the chain.',
        }
    },
    share: {
        my: 'Invitation link',
        link: 'Share link',
        child: 'My inviting date',
        userid: 'UserId',
        join: 'Join Time',
        state: 'State',
        state0: 'Inactivated',
        state1: 'Activated',
        invite: 'Invitation Code',
        qrbtn: 'Share QR code',
        qrtitle: 'Invitation QR code',
        qrtips: 'Scan the code or save the picture to share',
        nodata: "No sharing record",
        tips: 'Keep pushing the number: {people1} people, Active: {actived} people, A call ticket has been obtained：{people2}',
        tipsShort: 'Keep pushing the number: {people1} people, Active: {actived} people',
        tips2: 'There are no call tickets available',
        tips3: 'Whether to collect the call ticket {account}？ Get the number：{num}',
        tiptitle: "To receive",
        unget: "Call card not collected",
        sharetip: "Invite members to register and activate their accounts, and you will receive a premium summoning coupon as a reward. The more you invite, the more you will receive!",
        getsuc: "You received a {num} call ticket",
        myid: "My ID",
        activetime: "Activation time"
    },
    setting: {
        title: 'Accounting Setting',
    },
    address: {
        title: 'Set Address',
        address: 'wallet address',
        p_address: 'Please enter the wallet address',
        save: 'Change address',
    },
    activate: {
        title: 'Activate the hero - ',
        name: 'Nickname',
        btn: 'Confirm activation',
        ok: 'Activation succeeded',
        go: 'Activat',
    },
    nickname: {
        title: 'What should we call you ?',
        content: 'Please select a nickname. This can be changed later.',
        p_nickname: 'Please enter the nickname',
        p_nicknamemax: 'A nickname cannot exceed 10 characters in length',
        ok: 'Nickname modified successfully',
        unchanged: 'Nickname unchanged',
        name: 'Name',
        save: 'Save Change',
        tip: 'No more than 10 characters',
    },
    email: {
        title: 'Bind Emailbox',
        title1: 'Setup Emailbox',
        email: 'Email',
        p_email: 'Please enter the email',
        err_email: 'Email format error',
        save: 'Change email',
        noBind: 'No mailbox is bound yet',
        google: 'Man-machine verification must',
        p_set: 'Please bind the mailbox',
        limit: 'Character out of limit',
        emailcode: "Email Code",
        send: "Send",
        p_emailcode: "Please enter the email verification code",
        modiEmailsuc: "Email address Modified successfully",
        modisuc: "Modify the success"
    },
    code: {
        title: 'Verify your email',
        content: 'We have sent you a verifity code in email <span class="c-f">{email}</span> Please verify it before you can this email to login',
        code: 'Code',
        tip: 'Closing this will cancel the setup',
        p_code: 'please enter the code',
        w_code: 'login password',
        pwd_code: 'please enter login password',
        pwd_newcode: "Please enter a new login password"
    },
    pass: {
        title: 'Setup new password',
        pass: 'Password',
        oldPass: 'Old password',
        newPass: 'New password',
        conPass: 'Confirm password',
        rePass: 'Retype new password',
        p_oldPass: 'please enter the old password',
        p_pass: 'please enter the password',
        p_rePass: 'please retype the new password',
        p_conPass: "Please enter the password again",
        err_rePass: 'retype password Error',
        save: 'Change Password',
        savefund: "Change fund password",
        tip: 'Your password has been reset <a href="{origin}/login" target="_blank" rel="noopener noreferrer">Login</a> using your new password',
        code: "verification code",
        confirmpas: "Confirm new password",
        tip2: "Your password has been reset",
        tip3: "Login using your new password"
    },
    fund: {
        fund: 'Fund password',
        title: 'Set new fund password',
        loginPass: 'Login password',
        newPass: 'New Fund Password',
        rePass: 'Repeat fund password',
        p_loginPass: 'Please enter email code',
        p_pass: 'Please enter the fund password',
        save: 'Set password',
        savefund: "Set fund password",
        tip: 'Your password has been updated',
        p_set: 'Please set the fund password',
        remian: 'Code',
        bindE: 'Bound Mailbox',
        p_newpass: "Please enter your new fund password"
    },
    trans: {
        number: 'Quantity',
        able: 'Wallet Balance',
        total: 'All',
        address: 'Address',
        default: 'Default',
        arrival: 'Arriver amount',
        fee: 'Handling fee',
        p_number: 'please enter the quantity',
        p_numberda: 'Quantity must be greater than zero',
        p_address: 'please enter the address',
        err_able: 'Insufficient available balance',
        err_min: 'Minimum deposit amount ',
        ok: 'Successful transaction',
        to: 'Go to my activity',
        name: 'Name',
        sellAt: 'Sell price',
        WarningTip: "Please do not close the window before the transaction is completed, otherwise your assets may suffer losses.",
        WarningTip1: "The accounts of each zone server on this platform are independent of each other, please confirm carefully before depositing; ",
        p_sell: 'please enter sell amount',
        confirmTip: 'The equipment is being confirmed in the block and is temporarily unavailable for trading',
        err_tokenid: 'While the block is being confirmed, transactions are not currently supported, please refresh the interface and try again later!',
        min_number: 'The minimum withdrawal quantity is',
        min_quantity: `Minimum extraction quantity {name}`,
        holder: 'Holder',
        claimTip: 'The NFT will be transferred from the game to your wallet.',
        p_deposit: 'Please activate the MDragon first',
        depositTip: 'The NFT will be transferred from your wallet to the game.',
        withdrawTip: 'The NFT will be withdrawn from the NFT111 protocol to your wallet.',
    },
    guide: {
        step: {
            1: 'Create Metamask Wallet',
            2: 'Deposit RDVs',
            3: 'Download game',
            4: 'Create Hero',
        },
        0: {
            title: 'Getting Started With Dragon net',
            content: `Dragon Valley Warriors is a simple and fun newest role-playing combat game. Players can feel the brand new 3d character effects in the game, allowing you to easily start the latest copy combat experience in the game. Download it if you like it!`,
        },
        1: {
            title: 'Great Your Metamask Wallet',
            content: `Metamask Wallet is your boarding pass to our digital nation`,
            title1: 'Download & setup',
            p1: 'Extension',
            chrome: 'Chrome',
            firefox: 'Firefox',
            title2: 'Create your BSC address',
            p2: 'What is Metamask Wallet for?',
            answer1: 'Login Dragon net game with metamask BSC address',
            answer2: 'Bind the mailbox according to the interface prompts',
        },
        2: {
            title: 'Deposit RDVs',
            content: `Metamask Wallet is your boarding pass to our digital nation`,
            title1: 'Deposit RDVs',
            p1: 'Use the metamask BSC address as the account to ',
            p2: 'log in',
            p3: ' to the dragon net market',
            answer1: 'Deposit RDVS to this address and these coins will be used to pay for compound heroes',
        },
        3: {
            title: 'Download game',
            title1: 'Browser',
            title2: 'Mobile',
            and: 'Android',
            ios: 'iOS',
        },
        4: {
            title: 'Create Hero',
            title1: 'Compound Hero in game',
            p1: 'Log in to the game system and start create HERO',
            answer1: `Enter the game, select the hero character, and pay the fee to complete the creation of the character. All preparations are completed, let's start the battle game`,
        },
    },
    tip: {
        noEmail: 'Bind the mailbox and set the password'
    },
}
