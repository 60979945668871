import Vue from 'vue'
import App from './App.vue'
import routes from './router'
import VueRouter from "vue-router";
import "./style/index.scss";
import "swiper/dist/css/swiper.min.css";
import Bot from "@/components/Bot.vue";
Vue.component('bot', Bot)

if (window.ethereum) {
  window.web3 = new Web3(window.ethereum);
} else if (window.web3) {
  window.web3 = new Web3(web3.currentProvider);
}

//控制台
// import './vconsole'

// vuex
import store from './store'
Vue.prototype.$store = store

//引入web3方法
import Web3 from 'web3'
Vue.prototype.$Web3 = Web3

//全局引入ElementUI组件
//$msgbox(options)
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
// import localeen from 'element-ui/lib/locale/lang/en'
// import localetw from 'element-ui/lib/locale/lang/zh-TW'
Vue.use(ElementUI);

//滑动验证码
import SlideVerify from 'vue-monoplasty-slide-verify';
Vue.use(SlideVerify);

//过滤器
import * as filters from "@/common/filter.js";
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

//请求
import api from "./api";
import axios from "./api/request";
Vue.prototype.$axios = axios;
Vue.prototype.$api = api;

//引入语言包
// import i18n from "./language/index18.js";
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
// 注册i18n实例并引入语言文件
const i18n = new VueI18n({
  locale: 'tw', // 定义默认语言为繁体中文
  messages: {
    'tw': require('@/language/tw.js'),
    'en': require('@/language/en.js'),
    'in': require('@/language/in.js'),
  },
  // 隐藏警告
  silentTranslationWarn: true
})

// 一款好用的本地存储插件
import storage from "storejs"
Vue.prototype.$storage = storage

//粘贴板
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard);

//二维码
import VueQr from 'vue-qr'
Vue.component('VueQr', VueQr)

Vue.use(VueRouter)
const router = new VueRouter({
  routes,
  // mode: routerMode,
  // hashbang: false,
  // history: true,
  strict: process.env.NODE_ENV !== "production",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (from.meta.keepAlive) {
        from.meta.savedPosition = document.body.scrollTop;
      }
      return { x: 0, y: to.meta.savedPosition || 0 };
    }
  },
});

// 解决IOS上输入和滑动冲突
window.document.ontouchmove = (e) => {
  document.querySelectorAll("input").forEach((e) => {
    e.blur();
  });
  document.querySelectorAll("textarea").forEach((e) => {
    e.blur();
  });
};

//检测浏览器刷新
// window.onload = ()=> {
//   //设置默认sessionStorage值
//   storage.set('serveid',storage.get('serveid'))
// };

//引入moment
import moment from 'moment';
Vue.prototype.$moment = moment

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
