module.exports = {
    locale: '繁體中文',
    111: '本次交易遵循 NFT111 協議',
    menu: {
        market: '市場',
	    collect: '收集',
	    hatching: '孵化',
        megg: '元龍蛋',
        hatch: '孵化',
        my: '我的賬戶',
        account: '鑄造',
        activity: '活動',
        legion: '军团',
        share: '邀请',
        setting: '設置',
        logout: '退出',
        login: '登錄',
        discord: "Discord",
        foot: ' 用戶條款',
        app: '下載',
        area: '服務區',
        whitepaper: "白皮書",
        game: "遊戲",
        dragongame: "小龍",
        selectRole: "選擇角色",
        createRole: "創建新角色",
        nowServe: "當前區服"
    },
    copy: {
        ok: '複製成功',
        on: '複製失敗',
    },
    element: {
        all: '全部',
        select_gun: '請選擇裝備類型',
        online1: "遊戲線上不能領取",
        online2: "遊戲線上不能提出"
    },
    messageBox: {
        tip: '溫馨提示',
        tip2: '提示',
        warntip: "溫馨提示",
        ok: '好的',
        close: '關閉',
        confirm: '確認',
        cancel: '取消',
        submit: '提交',
        save: '保存',
        logoutTip: '您確定要退出嗎',
        emailTip: '請先完成郵箱綁定',
        metamaskTip: `<h3>請先安裝 MetaMask</h3><p>MetaMask 可在 <a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn" target="_blank">Chrome</ a>、<a href="https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/" target="_blank">Firefox</a> 和打開。 你可以在這裡下載。</p>`,
        addressTip: `<h3>請先鏈接到帳戶</h3><p>您需要先連接到有效的帳戶才能繼續。</p>`,
        bscTip: '<h3>請先鏈接到BSC</h3><p>您需要在登錄前切換到幣安鏈。</p>',
        network: '切換到 BSC 網絡',
        metamaskErr: 'MetaMask錯誤',
        connect: '連接帳戶',
        loginTip: `請先登錄，此操作需登錄後才能進行。`,
        accountTip: `檢測到當前MetaMask賬號({address1})不是登錄賬號({address2})，請切換MetaMask的賬號，否則將影響後續使用。`,
        accountTitle: '帳號異常',
        accountBtn: '新帳戶登錄',
        accountBtn1: '切換MetaMask',
        createRole: '您還沒有創建角色, 是否要創建它',
        createRoleTitle: "創建角色",
        selectServe: "選擇區服",
        selectTip: "请選擇區服",
        depositTip1: '1.本遊戲各區遊戲角色相互獨立，請確認後存入.',
        depositTip2: '2.請不要在交易完成前關閉窗口，否則可能會讓您的帳戶蒙受損失',
        noServers: "暫無區服",
        newemail: "確認",
        gametitle: "掃碼下載",
        active: "是否激活元龍{name}?",
        activetip: "支付成功！ 需要花費幾分鐘進行鏈上確認，請耐心等待...",
        activetip2: '恭喜您已激活{name}!',
        forgetip: '是否將您的登录密碼重置為<span class="t-bule">123456</span>',
        resetbtn: '立即重置',
        tibi: "24小時內僅可提幣1次； 每次提現後請耐心等候，將在24小時內轉入錢包。",
        nobalance: '請先儲值1MDV，再啟動帳號',
        buysuc: "购买成功",
        noopen: "暫未開放",
	    mdvTip: '儲值MDV',
	    mdvCofirm: '儲值MDV',
    },
    page: {
        page: '頁',
        of: 'of',
        next: '下一頁',
        prev: '上一頁',
        start: '開始',
    },
    login: {
        metamask: '使用BSC钱包登錄',
        email: '郵箱賬戶密碼登錄',
        remember: '記住密碼',
        forget: '忘記密碼？',
        where: '不知道從哪裡開始？',
        start: '入門',
        content: '輸入妳綁定的郵箱地址，我們將會向妳發送密碼重置鏈接。',
        to: '郵箱驗證',
        tiptitle: '新用户五步走',
        tip1: '第一步：使用BSC钱包登錄',
        tip2: '第二步：绑定邮箱账户',
        tip3: '第三步：充值META',
        tip4: '第四步：激活帳號',
        tip5: '第五步：下载安装游戏APP',
        tip6: '使用邮箱账号登录APP，開啟元龍之戰！',
        guideText: 'MWars新用戶註冊指南',
        guideUrl: 'https://metadragon.gitbook.io/zh/guide/mwars'
    },
    forget: {
        title: '郵件已發送',
        content: '郵件已發送至 {email}，請按照郵件指引重置您的密碼。',
    },
    home: {
        time: '活動時間：2021-12-12 ~ 2021-12-22',
        zige: '活動參與資格：東風湖哇撒奮達科技窗打開寫邏輯',
        guize: '活動規則：大概是驕傲的好時機肯定會就開始帶回家客戶端接電話測試接口',
        btn: '購買',
        area: "區服"
    },
    summon: {
        summon1: "召喚1次",
        summon10: "召喚10次",
        summonnow: "立即召喚",
        blance: '餘額'
    },
    game: {
        qr: '二維碼登錄',
        title: '使用二維碼登錄',
        content: '請勿將此二維碼分享給任何人',
        tipQr: '請在登錄遊戲前綁定郵箱！',
    },
    presale: {
        content0: `開始遊戲前，您需要激活至少一個英雄角色，激活角色將花費{price} {coin1}。`,
        content1: `開始遊戲前，您需要激活至少一個英雄角色，激活角色將花費{price} {coin1}。`,
        content2: `激活支付後，交易確認大約需要5-10分鐘，請耐心等待`,
        content3: `角色激活成功，恭喜獲得裝備盲盒抽獎機會，快來試試吧`,
        btn0: '激活',
        btn1: '激活',
        btn2: '激活中...',
        btn3: '來試試你的運氣',
        tip0: '激活支付中',
        tip1: 'METAMASK支付後大概需要5~10分鐘進行區塊鏈確認...',
        name: {
            0: '洛基',
            1: '尼奥尔德',
            2: '弗雷',
            3: '芙蕾雅',
            4: '雷蒙德',
        },
    },
    market: {
        hero: "英雄",
        nodata: "暫無數據",
        filter: '篩選',
        clear: '清除篩選',
        camp: '種族',
        campList: {
            element1: "全部",
            element2: "天神",
            element3: "战神",
            element4: "祖神",
            element5: "圣兽",
            element6: "人神",
        },
        skillTitle: '技能属性',
        skill: {
            // 火、冰、風、光、暗
            element1: "全部",
            element2: "火",
            element3: "冰",
            element4: "風",
            element5: "光",
            element6: "暗",
        },
        level: '等級',
        dragon: '元龍',
        Amount: '金额',
        buydragon: "購買元龍",
        buydragontip: "在完成交易之前不要关闭",
        equip: '卡牌',
        addDragonBtn: "生成卡牌",
        addDragonBox: {
            title: "添加元龍",
            id: "選擇生成級別",
            addQuantity: "生成数量",
            totalQuantity: '可生成總數量',
            remainQuantity: '剩餘可生成數量',
            successTxs: '交易成功',
            failedTxs: '交易失敗',
            idPlaceholder: "请输入生成数量",
            btnCancel: "取消",
            btnConfirm: "确认",
            notes: "一旦元龍成功添加至您的帳戶，請勿再私下使用錢包進行轉移。若您使用錢包將元龍轉給他人，對方將無法正常使用，必須再將其轉回至您的錢包。如果您需要轉讓NFT，請通過市場公開拍賣。",
            success: "添加圣龙成功",
            costUsdt: '消耗USDT',
            costHfh: '消耗HFH',
            connectWallet: '請退出登錄連接錢包'
        },
        pet: '幻神',
        lingshi: '靈石',
        pwd: '資金密碼',
        input: "請輸入資金密碼",
        star: "星级",
        sort: {
            IdL: '最低ID',
            IdH: '最高ID',
            PriceL: '最低價格',
            PriceH: '最高價格',
            Latest: '最新的',
            LevelH: '最高等級',
            LevelL: '最低等級',
            stageH: '最高階數',
            stageL: '最低階數',
            strengthH: '最高強化等級',
            strengthL: '最低強化等級',
            starH: '最高星數',
            starL: '最低星數',
            tokenup: 'tokenid升序',
            tokendown: 'tokenid降序',
        },
        detail: {
            about: '關於',
            attr: '基本屬性',
            type: '資產類型',
            role: '角色限制',
            part: '部位類型',
            strength: '強化等級',
            star: '星級等級',
            stage: '階數等級',
            owner: '所有者',
            address: '所在地址',
        },
        profession: '職業',
        professionList: {
            element1: "全部",
            element2: "戰士",
            element3: "法師",
            element4: "坦克",
            element5: "輔助",
        },
        sorttitle: "排序",
        starrating: "星級",
        starrateList: {
            star0: "全部",
            star1: "1 星",
            star2: "2 星",
            star3: "3 星",
            star4: "4 星",
            star5: "5 星",
            star6: "6 星",
            star7: "7 星",
        },
        grade: "級別",
        gradeList: {
            grade0: "全部"
        },
        attr: {
            hp: '生命',
            att: '攻擊',
            defense: '防禦',
            hp_1: '生命',
            att_1: '攻擊',
            defense_1: '防禦',
            crit: '暴擊強度',
            crit_res: '暴擊抵抗',
            super_crit: '卓越一擊',
            vampire: '攻擊吸血',
            speed_pct: '移動速度',
            super_att: '最終傷害',
            super_defense: '最終免傷',
        },
        status: {
            withdrawCoin: '提币',
            withdraw: '提入錢包',
            withdrawtitle: '提入錢包',
            claim: '轉入錢包',
            claimtitle: '轉入錢包',
            depositCoin: '存入',
            deposit: '轉入遊戲',
            depositTitle: '轉入遊戲',
            sell: '賣出',
            cancel: '取消',
            buy: '買入',
            use: '已裝備',
            claiming: '領取中',
            confirm: '確認中',
            selling: '賣出中',
            auction: '拍賣',
            auctioning: '拍賣中',
            auctioncard: "拍賣卡牌",
            auctionadd: '參與出價',
            minPrice: "起拍價",
            minAdd: "最低出價",
            add: '出價',
            nftErrorTransferredOut: 'NFT已被转出，请转回后再操作',
            old: '舊'
        },
        price: "價格",
        history: {
            title: '銷售記錄',
            buyer: '買方',
            seller: '賣方',
        },
        fee: "手續費",
        sort: {
            latest: "最新上架",
            lowP: "價格最低",
            hightP: "價格最高",
        }
    },
    dragon: {
        back: "返回",
        info: '基礎内容',
        name: "名稱",
        refined: "精煉",
        level: "等級",
        hp: "全隊生命",
        atk: "全隊攻擊",
        specialAttributes: "特殊内容",
        allatk: "全隊攻擊",
        allacc: "全隊速度",
        allspd: "全隊免傷",
        effect: "精煉效果",
        refinelv: "精煉等級",
        addatk: "技能傷害新增",
        totalSkill: "總傷害",
        dragonskill: "元龍技能",
        bone: "Bone eroding inflammation",
        skill: "被動技能",
        ji: "級",
        lv4ji: "技能4級激活",
        lv8ji: "技能8級激活",
        lv12ji: "技能12級激活",
        suc: "您已激活元龍",
        notactive: "未激活",
    },
    card: {
        buy: "購買",
        unsell: "下架",
        info: "基礎資訊",
        name: "名稱",
        grade: "級別",
        camp: "種族",
        profession: "職業",
        power: "戰力",
        level: "等級",
        star: "星級",
        num: "編號",
        currentPrice: "當前報價",
        totalamount: "總交易額",
        attributes: "基礎内容",
        blood: "血量",
        attack: "攻擊",
        defense: "防禦",
        speed: "速度",
        sattributes: "特殊内容",
        rate: "暴擊率",
        control: "控制",
        riot: "抗暴",
        free: "免傷",
        heal: "治療",
        damage: "傷害加成",
        spelldamage: "法傷",
        tospelldamage: "法免",
        crit: "暴擊傷害",
        harm: "抗控",
        hit: "命中",
        dodge: "閃避",
        treatment: "被治療",
        pdamage: "物傷",
        damagefree: "物免",
        heroSkill: "英雄技能",
        history: "交易歷史",
        token: "卡牌token",
        time: "交易時間",
        from: "當前得主",
        to: "交易者",
        price: "交易價格",
        rice: "Putin Rice",
        type: "類型",
        zhudong: "主動技能",
        beidong: "被動技能",
        status: {
            operate: "不可操作",
            synchronizing: "同步遊戲數據中",
            chain: "上鏈中",
            intogame: "存入遊戲中",
            confirmation: "賣出確認中",
            updategame: "更新遊戲數據中",
            chainfail: "上鏈失敗",
            syncfail: "同步失敗",
            gameAssets: "遊戲資產",
            walletAssets: "錢包資產",
            auctioned: "合約資產",
        },
        upgrade: "技能陞級",
        timeout: "拍賣中",
        modify: "修改價格",
        current_price: "當前價格",
        new_price: "請輸入新的價格",
        modifySuccess: "修改成功"
    },
    hero: {
        name: {
            "21": "弗雷",
            "22": "尼奧爾德",
            "23": "芙蕾雅",
            "31": "尼奧爾德",
            "32": "芙蕾雅",
            "33": "格歐費茵",
            "41": "弗雷",
            "42": "尼奧爾德",
            "43": "芙蕾雅",
            "51": "蘇爾",
            "52": "薇爾丹蒂",
            "53": "格歐費茵",
            "54": "萊娜斯",
            "55": "亞莉",
            "61": "艾美",
            "62": "貝爾納斯",
            "63": "拉烏利",
            "64": "安德瓦裡",
            "65": "巴德爾",
            "11001": "艾美",
            "11002": "南娜",
            "12001": "沙克特",
            "12002": "卡薩諾",
            "12003": "海尼爾",
            "12004": "祖達",
            "13001": "提爾",
            "13002": "摩墨斯",
            "13003": "納什加",
            "13004": "普羅米修斯",
            "13005": "魯道夫",
            "14001": "蘇爾",
            "14002": "菲妮克斯",
            "14003": "斯嘉麗",
            "14004": "赫拉",
            "14005": "戈莫克",
            "15001": "艾琳娜",
            "15002": "赫菲斯托斯",
            "15003": "赫斯提亞",
            "15004": "洛基",
            "15005": "糖果女巫",
            "15006": "聖誕老人",
            "21001": "曼尼",
            "21002": "貝爾納斯",
            "22001": "潔拉德",
            "22002": "斯卡曼德洛斯",
            "22003": "奧拉夫",
            "23001": "薇爾丹蒂",
            "23002": "灰鬃狼人",
            "23003": "阿瑞斯",
            "23004": "烏爾墨",
            "23005": "雅典娜",
            "24001": "博立爾",
            "24002": "莎琳",
            "24003": "弗麗嘉",
            "24004": "波塞冬",
            "24005": "雪人先生",
            "25001": "蓋亞",
            "25002": "宙斯",
            "25003": "索恩",
            "25004": "尼奧爾德",
            "31001": "拉烏利",
            "31002": "薩提洛斯",
            "32001": "佩德羅",
            "32002": "佈洛根",
            "32003": "丘比特",
            "33001": "卡沃",
            "33002": "伊師塔克",
            "33003": "布裡恩妮",
            "33004": "西芙",
            "33005": "布拉基",
            "33006": "格歐費茵",
            "34001": "小約翰",
            "34002": "辛德里",
            "34003": "赫爾墨斯",
            "34004": "喀什雷克",
            "34005": "梅麗雅",
            "35001": "阿耳忒彌斯",
            "35002": "卡迪雷恩",
            "35003": "弗雷",
            "35004": "維達爾",
            "41001": "凡賽堤",
            "41002": "盧西奧",
            "42001": "伊登",
            "42002": "摩迪",
            "42003": "納什",
            "43001": "達瓦",
            "43002": "阿波羅",
            "43003": "詩寇蒂",
            "43004": "維納斯",
            "44001": "悟空",
            "44002": "菲碧",
            "44003": "萊娜斯",
            "44004": "巴德爾",
            "45001": "萊昂尼達斯",
            "45002": "芙蕾雅",
            "45003": "奧丁",
            "45004": "托爾",
            "51001": "諾特",
            "51002": "巴達爾",
            "52001": "安德瓦裡",
            "52002": "希利蘇斯",
            "52003": "瓦利",
            "53001": "巴特薩",
            "53002": "烏爾德",
            "53003": "潘朵拉",
            "53004": "美杜莎",
            "53005": "雷恩菲爾德",
            "54001": "亞莉",
            "54002": "薩比娜",
            "54003": "里加",
            "54004": "厄瑞玻斯",
            "54005": "雷蒙德",
            "54006": "南瓜幽靈",
            "55001": "多米西亞",
            "55002": "哈迪斯",
            "55003": "赫卡忒",
            "55004": "海拉",
            "56": "神使",
            "1503": "黑衣人",
            "11901": "訓練師英雄",
            "12901": "訓練師英雄",
            "13901": "訓練師英雄",
            "14901": "訓練師英雄",
            "15901": "訓練師英雄",
            "21901": "訓練師英雄",
            "22901": "訓練師英雄",
            "23901": "訓練師英雄",
            "24901": "訓練師英雄",
            "25901": "訓練師英雄",
            "31901": "訓練師英雄",
            "32901": "訓練師英雄",
            "33901": "訓練師英雄",
            "34901": "訓練師英雄",
            "35901": "訓練師英雄",
            "41901": "訓練師英雄",
            "42901": "訓練師英雄",
            "43901": "訓練師英雄",
            "44901": "訓練師英雄",
            "45901": "訓練師英雄",
            "51901": "訓練師英雄",
            "52901": "訓練師英雄",
            "53901": "訓練師英雄",
            "54901": "訓練師英雄",
            "55901": "訓練師英雄",
        },
        status: {
            0: '隊伍中',
            1: '賣出',
            2: '鎖定中',
            3: '上鍊確認中',
            4: '賣出確認中',
            5: '取消',
            51: '買入',
            6: '禁止操作',
        },
    },
    account: {
        setAccount: '請完成您的帳戶設置',
        setEmail: '設置郵箱和密碼',
        wallet: '錢包',
        address: '地址',
        see: '查看',
        tip: '遊戲在線不能{type}',
        captip: "向右滑動填充拼圖",
        Activate: '激活',
        paimai: "拍賣",
        paimaiIng: "拍賣中",
        receive: '領取',
        Activateing: '激活中',
        hasActivate: '已激活',
        forsale: "出售中",
        salePrice: '售價',
        salecard: "出售卡牌",
        saleprice: "出售金额",
        saletip: `交易完成时，平台将收取出售金额的{fee}作为手续费`,
        sell: "出售",
        p_saleprice: "請輸入拍賣價格",
        saletip1: '當前傳輸網絡為BSC網絡',
        saletip2: `授權後，您的 NFT 將根據 NFT111 協議規則在市場上流通：
        <br />1. 任何人都可以出價不低於當前價格的 1.11 倍來強制轉讓 NFT 所有權。
        <br />2. 每筆交易，95%歸持有者，3%歸版權所有者，1%為交易手續費，1%歸歷史交易者。
        <br />3. 超時鎖定，超過一定時間沒有交易後，拍賣狀態將自動鎖定。`,
        shelf: "下架",
        shelfor: "是否下架",
        shelgsuc: "已成功上架",
        auctionsuc: "已成功起拍，請等待鏈上確認！",
        reAuctionsuc: "已成功出價，請等待鏈上確認！",
        depositsuc: "已成功存入，請等待鏈上確認！",
        depositsuc2: "已成功轉入遊戲，請等待鏈上確認！",
        claimsuc: '已成功領取',
        claimsuc2: '已成功轉入錢包',
        withdrawsuc: '已成功提出，請等待鏈上確認！',
        reAuctionfail: "NFT鎖定中，請等待鏈上確認！",
        activetips: '請先激活帳號',
        hasActivateTip: "帳號已激活",
        activetip_p: `您是否想使用<span style='color:#56A1FF;'>{type}</span>激活您的帳戶`,
        Activateaccount: "激活帳號",
        hasactive: '帳號已激活'
    },
    activity: {
        title: '活動',
        view: '查看完整活動',
        more: '更多',
        tip: {
            1: `您已成功充值 <span style="color:#67C23A">{amount} MDS</span>`,
            2: `您已成功選取 <span style="color:#67C23A">{amount2} MDS</span>`,
            3: `您以 <span style="color:#67C23A">{amount} BNB</span> 的價格購買英雄 <span style="color:#67C23A"{name}#{assets_id}</span>`,
            4: `您以 <span style="color:#67C23A">{amount} BNB</span> 的價格出售英雄 <span style="color:#67C23A">{name}#{assets_id}</span>`,
            5: `您已取消出售英雄 <span style="color:#67C23A">{name}#{assets_id}</span>`,
            6: `您所出售的英雄 <span style="color:#67C23A">{name}#{assets_id}</span> 以 <span style="color:#67C23A">{amount} BNB</span> 成交`,
            7: `您的提幣申請已提交，金額：<span style="color:#67C23A">{amount7} MDS</span>`,
            8: `您的提幣申請已被拒絕，金額：<span style="color:#67C23A">{amount8} MDS</span>`,
            9: `您已激活元龍 <span style="color:#67C23A">{name}#{assets_id}</span>`,
            11: `您召喚的英雄 <span style="color:#67C23A">{name}#{assets_id}</span> 已上鏈`,
            12: `您激活的元龍 <span style="color:#67C23A">{name}#{assets_id}</span> 已上鏈`,
            13: `您在遊戲中消耗了英雄 <span style="color:#67C23A">{name}#{assets_id}</span>`,
            14: `您的提幣申請已提交，金額：<span style="color:#67C23A">{amount14} ENFT</span>`,
            15: `您的提幣申請已被拒絕，金額：<span style="color:#67C23A">{amount15} ENFT</span>`,
            16: `您已成功充值 <span style="color:#67C23A">{amount} ENFT</span>`,
            17: `您已成功選取 <span style="color:#67C23A">{amount17} ENFT</span>`,
            18: `您領取了<span style="color:#67C23A">{amount}</span>張召喚券`,
            19: `您已從遊戲中領取英雄 <span style="color:#67C23A">{name}#{assets_id}</span> 到錢包，手續費：<span style="color:#67C23A">{fee}</span>MDS`,
            26: `您已從遊戲中領取元龍 <span style="color:#67C23A">{name}#{assets_id}</span> 到錢包，手續費：<span style="color:#67C23A">{fee}</span>MDS`,
            20: `您已從錢包存入英雄 <span style="color:#67C23A">{name}#{assets_id}</span> 到遊戲中`,
            21: `您已領取<span style="color:#67C23A">{name}#{assets_id}</span>，請等待鏈上確認`,
            22: `您已成功充值！`,
            23: `您已領取 <span style="color:#67C23A">{name}#{assets_id}</span>`,
            24: `您已從錢包存入元龍 <span style="color:#67C23A">{name}#{assets_id}</span> 到遊戲中`,
            25: `您已領取<span style="color:#67C23A">{name}#{assets_id}</span>，請等待鏈上確認`,
            30: `您以 <span style="color:#67C23A">{amount} BNB</span> 發起了卡牌 <span style="color:#67C23A">{name}#{assets_id}</span> 的拍賣`,
            31: `您的卡牌 <span style="color:#67C23A">{name}#{assets_id}</span> 無人出價，已從111協議解鎖，請自行提出`,
            32: `您拍賣的卡牌 <span style="color:#67C23A">{name}#{assets_id}</span> 以 <span style="color:#67C23A">{two_amount} BNB</span> 成交，您獲得收益：<span style="color:#67C23A">{amount} BNB</span>`,
            33: `您已成功競拍卡牌 <span style="color:#67C23A">{name}#{assets_id}</span>，出價：<span style="color:#67C23A">{amount} BNB</span>`,
            34: `您參與出價的卡牌 <span style="color:#67C23A">{name}#{assets_id}</span> 已有新的出價，得到分紅：<span style="color:#67C23A">{amount} BNB</span>`,
            35: `恭喜！您成功拍下卡牌 <span style="color:#67C23A">{name}#{assets_id}</span>，最終出價：<span style="color:#67C23A">{amount} BNB</span>`,
            36: `收益領取成功，數量：<span style="color:#67C23A">{amount} BNB</span>`,
            37: `您已從111協議合約中提出卡牌 <span style="color:#67C23A">{name}#{assets_id}</span> 到錢包`,

            50: `您已成功充值 <span style="color:#67C23A">{amount} HFH</span>`,
            54: `您已成功選取 <span style="color:#67C23A">{amount54} HFH</span>`,
            51: `您的提幣申請已提交，金額：<span style="color:#67C23A">{amount51} HFH</span>`,
            53: `您的提幣申請已被拒絕，金額：<span style="color:#67C23A">{amount53} HFH</span>`,

            52: `您已激活元龍 <span style="color:#67C23A">{name}#{assets_id}</span>`,

            55: `您已激活帳戶，消耗META：<span style="color:#67C23A">{amount}</span>`,
            56: `您已激活帳戶，消耗MDV：<span style="color:#67C23A">{amount}</span>`,
            57: `您已成功充值 <span style="color:#67C23A">{amount} MDV</span>`,

            60: `您領取了小龍，消耗 <span style="color:#67C23A">{amount} MDS</span>`,
            62: `您將卡牌 <span style="color:#67C23A">{name}#{assets_id}</span> 的拍賣價格修改為<span style="color:#67C23A">{amount} BNB</span>`,
	        63: `您已添加元龍 <span style="color:#67C23A">{name}#{assets_id}</span>`,
            '1-3': `你用 <span class="c-g">{amount} BNB</span> 從 <span class="c-g">賣家#{two_userid}</span> 買了 <a href="{origin}/gun/equip/{index_id}" target="_blank" rel="noopener noreferrer">裝備 {nftname}#{tokenid}</a>`,
            '1-4': `你上架出售 <a href="{origin}/gun/equip/{index_id}" target="_blank" rel="noopener noreferrer">裝備 {nftname}#{tokenid}</a>，賣出價格為 <span class="c-g">{amount} BNB</span>`,
            '1-5': `你取消了 <a href="{origin}/gun/equip/{index_id}" target="_blank" rel="noopener noreferrer">裝備 {nftname}#{tokenid}</a> 的售出掛單`,
            '1-6': `你申請向地址 <span class="c-c">{two_address}</span> 領取 <a href="{origin}/gun/equip/{index_id}" target="_blank" rel="noopener noreferrer">裝備 {nftname}#{tokenid}</a> 交易確認中`,
            '1-6-1': `你申請向地址 <span class="c-c">{two_address}</span> 領取 <a href="{origin}/gun/equip/{index_id}" target="_blank" rel="noopener noreferrer">裝備 {nftname}#{tokenid}</a> 交易成功，手續費 <span class="c-g">{fee} {coin}</span>`,
            '1-9': `你成功向 <span class="c-g">買家#{two_userid}</span> 出售 <a href="{origin}/gun/equip/{index_id}" target="_blank" rel="noopener noreferrer">裝備 {nftname}#{tokenid}</a>，賣出價格為 <span class="c-g">{amount} BNB</span>，扣除手續費後收到款項 <span class="c-g">{two_amount} BNB</span>`,
            '1-11': `你申請向地址 <span class="c-c">{two_address}</span> 存入 <a href="{origin}/gun/equip/{index_id}" target="_blank" rel="noopener noreferrer">裝備 {nftname}#{tokenid}</a> 交易確認中`,
            '1-11-1': `你申請向地址 <span class="c-c">{two_address}</span> 存入 <a href="{origin}/gun/equip/{index_id}" target="_blank" rel="noopener noreferrer">裝備 {nftname}#{tokenid}</a> 交易成功`,

            '2-3': `你用 <span class="c-g">{amount} BNB</span> 從 <span class="c-g">賣家#{two_userid}</span> 買了 <a href="{origin}/gun/pet/{index_id}" target="_blank" rel="noopener noreferrer">幻神 {nftname}#{tokenid}</a>`,
            '2-4': `你上架出售 <a href="{origin}/gun/pet/{index_id}" target="_blank" rel="noopener noreferrer">幻神 {nftname}#{tokenid}</a>，賣出價格為 <span class="c-g">{amount} BNB</span>`,
            '2-5': `你取消了 <a href="{origin}/gun/pet/{index_id}" target="_blank" rel="noopener noreferrer">幻神 {nftname}#{tokenid}</a> 的售出掛單`,
            '2-6': `你申請向地址 <span class="c-c">{two_address}</span> 領取 <a href="{origin}/gun/pet/{index_id}" target="_blank" rel="noopener noreferrer">幻神 {nftname}#{tokenid}</a> 交易確認中`,
            '2-6-1': `你申請向地址 <span class="c-c">{two_address}</span> 領取 <a href="{origin}/gun/pet/{index_id}" target="_blank" rel="noopener noreferrer">幻神 {nftname}#{tokenid}</a> 交易成功，手續費 <span class="c-g">{fee} {coin}</span>`,
            '2-9': `你成功向 <span class="c-g">買家#{two_userid}</span> 出售 <a href="{origin}/gun/pet/{index_id}" target="_blank" rel="noopener noreferrer">幻神 {nftname}#{tokenid}</a>，賣出價格為 <span class="c-g">{amount} BNB</span>，扣除手續費後收到款項 <span class="c-g">{two_amount} BNB</span>`,
            '2-11': `你申請向地址 <span class="c-c">{two_address}</span> 存入 <a href="{origin}/gun/pet/{index_id}" target="_blank" rel="noopener noreferrer">幻神 {nftname}#{tokenid}</a> 交易確認中`,
            '2-11-1': `你申請向地址 <span class="c-c">{two_address}</span> 存入 <a href="{origin}/gun/pet/{index_id}" target="_blank" rel="noopener noreferrer">幻神 {nftname}#{tokenid}</a> 交易成功`,

            '3-3': `你用 <span class="c-g">{amount} BNB</span> 從 <span class="c-g">賣家#{two_userid}</span> 買了 <a href="{origin}/gun/lingshi/{index_id}" target="_blank" rel="noopener noreferrer">靈石 {nftname}#{tokenid}</a>`,
            '3-4': `你上架出售 <a href="{origin}/gun/lingshi/{index_id}" target="_blank" rel="noopener noreferrer">靈石 {nftname}#{tokenid}</a>，賣出價格為 <span class="c-g">{amount} BNB</span>`,
            '3-5': `你取消了 <a href="{origin}/gun/lingshi/{index_id}" target="_blank" rel="noopener noreferrer">靈石 {nftname}#{tokenid}</a> 的售出掛單`,
            '3-6': `你申請向地址 <span class="c-c">{two_address}</span> 領取 <a href="{origin}/gun/lingshi/{index_id}" target="_blank" rel="noopener noreferrer">靈石 {nftname}#{tokenid}</a> 交易確認中`,
            '3-6-1': `你申請向地址 <span class="c-c">{two_address}</span> 領取 <a href="{origin}/gun/lingshi/{index_id}" target="_blank" rel="noopener noreferrer">靈石 {nftname}#{tokenid}</a> 交易成功，手續費 <span class="c-g">{fee} {coin}</span>`,
            '3-9': `你成功向 <span class="c-g">買家#{two_userid}</span> 出售 <a href="{origin}/gun/lingshi/{index_id}" target="_blank" rel="noopener noreferrer">靈石 {nftname}#{tokenid}</a>，賣出價格為 <span class="c-g">{amount} BNB</span>，扣除手續費後收到款項 <span class="c-g">{two_amount} BNB</span>`,
            '3-11': `你申請向地址 <span class="c-c">{two_address}</span> 存入 <a href="{origin}/gun/lingshi/{index_id}" target="_blank" rel="noopener noreferrer">靈石 {nftname}#{tokenid}</a> 交易確認中`,
            '3-11-1': `你申請向地址 <span class="c-c">{two_address}</span> 存入 <a href="{origin}/gun/lingshi/{index_id}" target="_blank" rel="noopener noreferrer">靈石 {nftname}#{tokenid}</a> 交易成功`,
        },
    },
    legion: {
        title: '我的军团',
        infoTooltip: '分享邀請鏈接，邀請朋友啟動帳號，加入你的軍團。你的軍團在Mwars花費MDS，包含啟動帳號、召喚、商店場景，你將獲得積分。',
        ruleDescription: '规则说明',
        level: '等级',
        points: '点',
        receive: '領取',
        canReceive: '可領取',
        rules1: '每7天可以領取一次',
        rules2: '最後領取時間: {finalTime}',
        viewDetails: '查看詳情',
        myLegion: '我的軍團',
        pointsRankingThisMonth: '本月積分排名',
        headerAccount: '帳戶',
        headerLevel: '等級',
        integralRules: '積分規則',
        integralRules1: '條件',
        integralRules2: '積分',
        integralRules3: '勇士',
        integralRules4: '挑戰領主關數過30關',
        integralRules5: '自己花費數6%',
        integralRules6: '騎士',
        integralRules7: '邀请6個勇士以上的會員',
        integralRules8: '自己花費數8%+直推會員花費數2%',
        integralRules9: '爵士',
        integralRules10: '邀请6個騎士以上的會員',
        integralRules11: '自己花費數10%+直推會員花費數3%',
        integralRules12: '',
        integralRules13: '',
        getMdsBox: {
            title: '获取MDS',
            amount: '数量',
            availablePoints: '可用积分',
            amountPlaceholder: '请输入要接收的 MDS 数量',
            btnAll: '全部',
            btnConfirm: '確認',
            success: '提取成功'
        },
        levelName: {
            1: '戰士',
            2: '勇士',
            3: '騎士',
            4: '爵士',
        },
        history: {
            title: '积分明细',
            myPoints: '我的积分',
            receivedMds: '已领取',
            tabPointAcquisition: '积分获取',
            tabMdsCollection: 'MDS领取',
            titleMdsCollectionRecord: 'MDS领取记录',
            more: '更多',
            typeMdsCollection: 'MDS领取',
            type: {
                1: '推荐奖励',
                2: '消费奖励',
            }
        },
        ranks: {
            title: '排行榜',
            subtitle: '根據當月的消費數據，設定獎金池。',
            rewardsLink: '本月军团獎勵',
            rewardsUrl: 'https://metadragon.gitbook.io/zh/events/mwars-legion',
            headerRanking: '排名',
            headerAccount: '账户',
            headerIntegral: '积分',
            myOwn: '自己',
        },
        rules: {
            title: '规则说明',
            1: '一、用户级别和积分算法',
            2: '1、戰士  ☆',
            3: '条件：激活或者未激活的新会员',
            4: '积分：无',
            5: '2、勇士 ★',
            6: '条件：挑战领主关数过30关',
            7: '积分：自己消费6%',
            8: '3、骑士★ ★',
            9: '条件：直推6个勇士以上的会员',
            10: '积分：自己消费8%+直推会员消费2%',
            11: '4、爵士★ ★ ★',
            12: '条件：直推6个骑士以上的会员',
            13: '积分：自己消费10%+直推会员消费3%',
            14: '计算方法如下：【推荐关系】A推荐了B',
            15: '【定义】：B的花费为N，B自己花费奖励比例为B1，推荐奖励比例为B2，A自己花费奖励比例为A1，推荐奖励比例为A2，那么：B积分=N*B1，A积分=N*A2，其他人无奖励。',
            16: '例如：A（★ ★ ★ ）→B(★ ★)，B花费100MDS。',
            17: '那么，B1=8%，A2=3%',
            18: '计算如下：',
            19: 'B积分增加 100*8%=8',
            20: 'A积分增加 100*3%=3',
            21: '二、领取MDS',
            22: '1、军团内玩家消费后，自己和直属上级立即获得积分.',
            23: '2、兑换比例：1积分可领取1MDS。',
            24: '三、排行榜',
            25: '每月积分排行榜前100名，时间是从每月1号到月末。',
            26: '导出数据表，手动链上空投发放。',
        }
    },
    share: {
        my: '邀請鏈接',
        link: '分享鏈接',
        child: '我的邀請',
        userid: '用戶Id',
        join: '註冊時間',
        state: '狀態',
        state0: '未激活',
        state1: '已激活',
        invite: '邀請碼',
        qrbtn: '分享二維碼',
        qrtitle: '邀請二維碼',
        qrtips: '手機掃碼或保存圖片分享',
        nodata: "暫無分享記錄",
        tips: '直推人数：{people1}人，已激活：{actived}人，已獲得召喚券：{people2}',
        tipsShort: '直推人数：{people1}人，已激活：{actived}人',
        tips2: '暫無領取的召喚券',
        tips3: '是否將召喚券領取到{account}？領取數量：{num}',
        tiptitle: "領取",
        unget: "未領取召喚券",
        sharetip: "邀請會員注册並激活帳號，將獲得1張高級召喚券獎勵，多邀請多送！",
        getsuc: "您領取了{num}張召喚券",
        myid: "我的ID",
        activetime: "激活時間"
    },
    setting: {
        title: '賬戶設置',
    },
    address: {
        title: '設置地址',
        address: '錢包地址',
        p_address: '請輸入錢包地址',
        save: '更改地址',
    },
    activate: {
        title: '激活英雄-',
        name: '暱稱',
        btn: '確認激活',
        ok: '激活成功',
        go: '激活',
    },
    nickname: {
        title: '我們應該怎麼稱呼你？',
        content: '請選擇一個暱稱。 這可以稍後更改。',
        p_nickname: '請輸入暱稱',
        p_nicknamemax: '昵称长度不可超过10位',
        ok: '暱稱修改成功',
        unchanged: '暱稱未變',
        name: '暱稱',
        save: '保存更改',
        tip: '不超過10個字符',
    },
    email: {
        title: '綁定郵箱',
        title1: '設置郵箱',
        email: '郵箱',
        p_email: '請輸入郵箱賬戶',
        err_email: '郵箱格式錯誤',
        save: '更改郵箱',
        noBind: '還沒有綁定郵箱',
        google: '人機驗證必須',
        p_set: '請綁定郵箱',
        limit: '字符超过限制',
        emailcode: "郵箱驗證碼",
        send: "發送",
        p_emailcode: "請輸入郵箱驗證碼",
        modiEmailsuc: "修改郵箱成功",
        modisuc: "修改成功"
    },
    code: {
        title: '驗證您的電子郵件',
        content: '我們已在電子郵件中向您發送了驗證碼 <span class="c-f">{email}</span> 請先驗證它，然後才能通過此電子郵件登錄',
        code: '驗證碼',
        tip: '關閉這將取消設置',
        p_code: '請輸入驗證碼',
        w_code: '登錄密碼',
        pwd_code: '請輸入登錄密碼',
        pwd_newcode: '請輸入新登錄密碼',
    },
    pass: {
        title: '設置新密碼',
        pass: '密碼',
        oldPass: '舊密碼',
        newPass: '新密碼',
        rePass: '重複密碼',
        conPass: "確認密碼",
        p_oldPass: '請輸入舊密碼',
        p_pass: '請輸入密碼',
        p_conPass: "請重新輸入密碼",
        p_rePass: '請輸入重複密碼',
        err_rePass: '重複密碼錯誤',
        save: '更改密碼',
        savefund: '更改資金密碼',
        tip: '您的密碼已被重置<a href="{origin}/login" target="_blank" rel="noopener noreferrer">使用您的新密碼登錄</a>',
        code: "驗證碼",
        confirmpas: "確認新密碼",
        tip2: "您的密碼已被重置",
        tip3: "使用新密碼登錄"
    },
    fund: {
        fund: '資金密碼',
        title: '設置新資金密碼',
        loginPass: '登錄密碼',
        newPass: '新資金密碼',
        rePass: '重複資金密碼',
        p_loginPass: '請輸入邮箱验证码',
        p_pass: '請輸入資金密碼',
        save: '設置密碼',
        savefund: '設置資金密碼',
        tip: '您的密碼已更新',
        p_set: '請設置資金密碼',
        remian: '验证码',
        bindE: '绑定邮箱',
        p_newpass: '請輸入新資金密碼',
    },
    trans: {
        number: '數量',
        able: '可用余额',
        total: '全部',
        address: '地址',
        default: '默認',
        arrival: '到賬',
        fee: '費用',
        p_number: '請輸入數量',
        p_numberda: '數量必須大於零',
        p_address: '請輸入地址',
        err_able: '可用餘額不足',
        err_min: '最小存入數量',
        ok: '交易成功',
        to: '轉到我的活動',
        name: '名稱',
        sellAt: '售價',
        WarningTip: "請不要在交易完成前關閉窗口，否則有可能讓您的資產蒙受損失。",
        WarningTip1: "本平台各區服角色賬戶相互獨立，請仔細確認後再存入；",
        p_sell: '請輸入銷售金額',
        confirmTip: '該裝備正在區塊確認中，暫不可交易',
        err_tokenid: '區塊確認中，暫不支持交易，請稍後刷新界面重試！',
        min_number: '最小提幣數量爲',
        min_quantity: `最小選取數量{name}`,
        holder: '持有者',
        claimTip: 'NFT將從遊戲中轉入到您的錢包',
        p_deposit: '請先激活元龍',
        depositTip: 'NFT將從您的錢包轉入到遊戲中使用',
        withdrawTip: 'NFT將從NFT111協議合約中提出並轉入到你的錢包',
    },
    guide: {
        step: {
            1: '創建 Metamask 錢包',
            2: '帳戶創建',
            3: '遊戲下載',
            4: '英雄創建',
        },
        0: {
            title: '龍谷奇兵入門',
            content: `龍谷奇兵是一款簡單好玩的最新角色扮演戰鬥小遊戲，玩家在遊戲中能夠感受到全新的3d立體人物效果，讓你在遊戲中輕鬆的就能開啟最新的副本戰鬥體驗。喜歡就來下載吧！`,
        },
        1: {
            title: '創建你的 Metamask 錢包',
            content: `Metamask 錢包是進入我們遊戲的註冊入口`,
            title1: '下載和設置',
            p1: '擴展程序',
            chrome: '谷歌',
            firefox: '火狐',
            title2: '創建您的 BSC 地址',
            p2: 'Metamask 錢包有什麼用？',
            answer1: '使用Metamask BSC地址登錄龍谷奇兵遊戲',
            answer2: '根據界面提示綁定郵箱',
        },
        2: {
            title: '設置遊戲賬號',
            content: `Metamask 錢包是進入我們遊戲的註冊入口`,
            title1: '帳戶創建',
            p1: '使用metamask BSC地址作為賬戶',
            p2: '登錄',
            p3: '龍谷奇兵官方商城',
            answer1: '向该地址存入RDVS，这些币將用来支付合成英雄',
        },
        3: {
            title: '下載遊戲',
            title1: '瀏覽器',
            title2: '移動端',
            and: '安卓',
            ios: '蘋果',
        },
        4: {
            title: '創建英雄',
            title1: '在遊戲中合成英雄',
            p1: '登录游戏系统，开始创建英雄角色',
            answer1: '进入游戏，选择英雄角色，并支付费用，即可完成角色创建.所有准备工作完成了，开始战斗游戏吧',
        },
    },
    tip: {
        noEmail: '請先綁定郵箱和設置密碼 '
    },
}
