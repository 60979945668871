module.exports = {
    locale: '印尼文',
    111: 'Transaksi sesuai protokol NFT111',
    menu: {
        market: 'Market',
	    collect: 'Collectibles',
	    hatching: 'Hatching',
        megg: 'MEgg',
        hatch: 'Hatch',
        my: 'Akun saya',
        account: 'Mint',
        activity: 'Aktivitas',
        legion: 'Legion',
        share: 'Undang',
        setting: 'Setting',
        logout: 'Logout',
        login: 'Login',
        discord: "Discord",
        foot: ' Syarat penggunaan',
        app: 'Unduh',
        area: 'Area server',
        whitepaper: "Whitepaper",
        game: "Game",
        dragongame: "Dragon",
        selectRole: "Pilih karakter",
        createRole: "Karakter baru",
        nowServe: "Server saat ini",
    },
    copy: {
        ok: 'Copy berhasil',
        on: 'Copy gagal',
    },
    element: {
        all: 'Semua',
        select_gun: 'Pilih tipe equip',
        online1: "Game tidak tersedia secara online",
        online2: "Game tidak bisa secara online"
    },
    messageBox: {
        tip: 'Reminder',
        tip2: 'Tips',
        warntip: "Reminder",
        ok: 'Ok',
        close: 'Tutup',
        confirm: 'Tentu',
        cancel: 'Batal',
        submit: 'Kirim',
        save: 'Deposit',
        logoutTip: 'Yakin keluar?',
        emailTip: 'Bind email terlebih dahulu',

        metamaskTip: `<h3>Silakan install MetaMask terlebih dahulu</h3><p>MetaMask tersedia di <a href="https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn" target="_blank">Chrome</ a>、<a href="https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/" target="_blank">Firefox</a> dan buka。Anda dapat download disini。</p>`,
        addressTip: `<h3>Tautkan ke akun terlebih dahulu</h3><p>Anda harus terhubung ke akun valid untuk melanjutkan</p>`,
        bscTip: '<h3>Silakan link ke BSC</h3><p>Anda perlu beralih ke Binance Chain sebelum login。</p>',
        network: 'Ganti server BSC',
        metamaskErr: 'MetaMask Error',
        connect: 'Link akun',
        loginTip: `Login terlebih dahulu，Operasi ini harus login terlebih dahulu。`,
        accountTip: `Terdeteksi akun MetaMask saat ini ({address1}) bukan akun login ({address2}), silakan ganti akun MetaMask, jika tidak maka akan mempengaruhi penggunaan selanjutnya.`,
        accountTitle: 'Akun error',
        accountBtn: 'Login akun baru',
        accountBtn1: 'Ganti MetaMask',
        createRole: 'Anda belum buat karakter, apakah ingin membuatnya',
        createRoleTitle: "Karakter baru",
        selectServe: "Pilih server",
        selectTip: "Silakan pilih server",
        depositTip1: '1.Karakter di setiap area game tidak bergantung satu sama lain，Harap konfirmasi dan deposit.',
        depositTip2: '2.Harap jangan tutup sampai transaksi selesai, atau dapat kehilangan akun Anda',
        noServers: "No server",
        newemail: "Yakin",
        gametitle: "Scan untuk donwload",
        active: "Aktifkan MDragon{name}?",
        activetip: "Pembayaran berhasil！ Butuh beberapa menit untuk konfirmasi chain, harap bersabar...",
        activetip2: 'Selamat sudah aktif{name}!',
        forgetip: 'Apakah akan reset ulang password ke<span class="t-bule">123456</span>',
        resetbtn: 'Reset',
        tibi: "Harap menunggu dengan sabar setelah withdrawal, akan ditransfer ke wallet dalam waktu 24 jam.",
        nobalance: 'Silakan top up 1 MDV terlebih dahulu, lalu aktifkan akun Anda',
        buysuc: "Pembelian berhasil",
        noopen: "Belum terbuka",
	    mdvTip: 'Top Up MDV',
	    mdvCofirm: 'Top Up',
    },
    page: {
        page: 'Page',
        of: 'Of',
        next: 'Next',
        prev: 'Prev',
        start: 'Mulai',
    },
    login: {
        metamask: 'Gunakan BSC wallet login',
        email: 'Login email dan password',
        remember: 'Ingat password',
        forget: 'Lupa password？',
        where: 'Tidak tahu mulai darimana？',
        start: 'Mulai',
        content: 'Masukan alamat email bind，Kami akan mengirim link reset password Anda。',
        to: 'Verifikasi email',
        tiptitle: '5 step untuk User baru',
        tip1: 'Step 1：Gunakan BSC wallet login',
        tip2: 'Step 2：Bind akun email',
        tip3: 'Step 3：Top up HFH',
        tip4: 'Step 4：Aktif Holy nomor rekening',
        tip5: 'Step 5：Download APP',
        tip6: 'Gunakan akun email login APP，buka Tantang MWars！',
        guideText: 'Panduan Pendaftaran Pengguna Baru MWars',
        guideUrl: 'https://metadragon.gitbook.io/id/guide/mwars'
    },
    forget: {
        title: 'Email terkirim',
        content: 'Email telah dikirim ke {email}, harap ikut petunjuk di email untuk atur ulang password Anda。',
    },
    home: {
        time: 'Waktu event：2021-12-12 ~ 2021-12-22',
        zige: 'Kualifikasi partisipasi：Dongfeng Lake jendela teknologi sapenda membuka logika tulis',
        guize: 'Aturan event：Saat yang tepat untuk bangga akan mulai bawa pulang klien menjawab telepon untuk menguji antarmuka',
        btn: 'Beli',
        area: "Area server"
    },
    summon: {
        summon1: "Summon1",
        summon10: "Summon10",
        summonnow: "Summon sekarang",
        blance: 'Saldo'
    },
    game: {
        qr: 'Kode QR login',
        title: 'Gunakan Kode QR login',
        content: 'Harap tidak bagikan kode QR ini kepada siapa pun',
        tipQr: 'Sebelum login game bind email terlebih dahulu！',
    },
    presale: {
        content0: `Sebelum game dimulai，perlu aktifkan min 1 karakter，fee aktifkan karakter{price} {coin1}。`,
        content1: `Sebelum game dimulai，perlu aktifkan min 1 karakter，fee aktifkan karakter{price} {coin1}。`,
        content2: `Setelah aktifkan pembayaran, transaksi membutuhkan waktu sekitar 5-10 menit，mohon menunggu`,
        content3: `Karakter berhasil diaktifkan，selamat memenangkan kesempatan undian blind box，silakan coba`,
        btn0: 'Aktif',
        btn1: 'Aktif',
        btn2: 'Sedang diaktifkan...',
        btn3: 'Coba keberuntungan kamu',
        tip0: 'Sedang aktifkan pembayaran',
        tip1: 'METAMASK Dibutuhkan sekitar 5-10 menit untuk konfirmasi blockchain setelah pembayaran...',
        name: {
            0: 'Rocky',
            1: 'Niord',
            2: 'Frey',
            3: 'Freya',
            4: 'Raymond',
        },
    },
    market: {
        hero: "Pahlawan",
        nodata: "Tidak ada data",
        filter: 'Filter',
        clear: 'Clear filter',
        camp: 'Clan',
        campList: {
            element1: "Semua",
            element2: "Dewa",
            element3: "Chita",
            element4: "Ruishou",
            element5: "Therion",
            element6: "Demon",
        },
        skillTitle: 'Atribut kemampuan',
        skill: {
            // 火、冰、風、光、暗
            element1: "Semua",
            element2: "api",
            element3: "es",
            element4: "Angin",
            element5: "cahaya",
            element6: "gelap",
        },
        level: 'Lv',
        dragon: 'MDragon',
        equip: 'MHero',
        addDragonBtn: "Tambah MDragon",
        addDragonBox: {
            title: "Tambahkan MetaDragon",
            id: "Pilih tingkat generasi",
            addQuantity: "Hasilkan jumlah",
            totalQuantity: 'Jumlah jumlah yang dapat dihasilkan',
            remainQuantity: 'Kebanyakan jumlah produksi',
            successTxs: 'Perdagangan berhasil',
            failedTxs: 'Transaksi gagal',
            idPlaceholder: "Masukkan jumlah yang dihasilkan",
            btnCancel: "Batal",
            btnConfirm: "OK",
            notes: "Setelah MDragon berhasil ditambahkan ke akun Anda, harap tidak melakukan transfer secara pribadi menggunakan wallet. Jika Anda mentransfer MDragon ke orang lain menggunakan wallet, mereka tidak akan dapat menggunakannya, dan mereka wajib mentransfernya kembali ke wallet Anda. Jika Anda ingin mentransfer NFT, silakan melakukannya melalui public auction di Market.",
            success: "MDragon sudah ditambahkan",
            costUsdt: 'Menggunakan USDT',
            costHfh: 'Menggunakan HFH',
            connectWallet: 'Silakan log keluar dan koneksi ke dompet'
        },
        pet: 'Phantom',
        lingshi: 'Spirit stone',
        pwd: 'Kode fund',
        input: "Silakan masukan kode fund",
        star: "Star",
        sort: {
            IdL: 'ID Terendah',
            IdH: 'ID Tertinggi',
            PriceL: 'Harga terendah',
            PriceH: 'Harga tertinggi',
            Latest: 'Terbaru',
            LevelH: 'Lv tertinggi',
            LevelL: 'Lv terendah',
            stageH: 'Stage tertinggi',
            stageL: 'Stage terendah',
            strengthH: 'Lv strength tertinggi',
            strengthL: 'Lv strength terendah',
            starH: 'Star tertinggi',
            starL: 'Star terendah',
            tokenup: 'tokenid naik',
            tokendown: 'tokenid turun',
        },
        detail: {

            about: 'about',
            attr: 'Atribut dasar',
            type: 'Tipe aset',
            role: 'Limit karakter',
            part: 'Tipe bagian',
            strength: 'Strength lv',
            star: 'Star lv',
            stage: 'Stage lv',
            owner: 'Pemilik',
            address: 'Alamat',
        },
        profession: 'Profesi',
        professionList: {
            element1: "Semua",
            element2: "Warrior",
            element3: "Magician",
            element4: "Tank",
            element5: "Assist",
        },
        sorttitle: "Urutan",
        starrating: "Lv star",
        starrateList: {
            star0: "Semua",
            star1: "1 Star",
            star2: "2 Star",
            star3: "3 Star",
            star4: "4 Star",
            star5: "5 Star",
            star6: "6 Star",
            star7: "7 Star",
        },
        grade: "Grade",
        gradeList: {
            grade0: "Semua"
        },
        attr: {
            hp: 'HP',
            att: 'ATK',
            defense: 'DEF',
            hp_1: 'HP',
            att_1: 'ATK',
            defense_1: 'DEF',
            crit: 'Crit',
            crit_res: 'Crit Resist',
            super_crit: 'Outstanding Strike',
            vampire: 'ATK vampire',
            speed_pct: 'Speed gerak',
            super_att: 'Total damage',
            super_defense: 'Total Immune',
        },
        status: {
            withdrawCoin: 'Withdraw',
            withdraw: 'Pengunduran',
            withdrawtitle: 'Penarikan dompet',
            claim: 'Masukan dompet',
            claimtitle: 'Masukan dompet',
            depositCoin: 'Deposit',
            deposit: 'Transfer game',
            depositTitle: 'Transfer game',
            sell: 'Jual',
            cancel: 'Batal',
            buy: 'Beli',
            use: 'Equip',
            claiming: 'Klaim',
            confirm: 'Konfirmasi',
            selling: 'Kejual',
            auction: 'Bid',
            auctioning: 'Auction',
            auctioncard: "Auction kartu",
            auctionadd: 'Ikut bid',
            minPrice: "Harga awal",
            minAdd: "Tawaran minimum",
            add: 'Tawaran',
            nftErrorTransferredOut: 'NFT telah di transfer keluar, silakan kembalikan sebelum melanjutkan.',
            old: 'Lama'
        },
        price: "Harga",
        history: {
            title: 'List penjualan',
            buyer: 'Pembeli',
            seller: 'Penjual',
        },
        fee: "Fee",
        sort: {
            latest: "Terbaru",
            lowP: "Harga terendah",
            hightP: "Harga tertinggi",
        }
    },
    dragon: {
        back: "Kembali",
        info: 'Info',
        name: "Nama",
        refined: "Refine",
        level: "Lv",
        hp: "All HP",
        atk: "All ATK",
        specialAttributes: "Spesial atribut",
        allatk: "All ATK",
        allacc: "All Speed",
        allspd: "All Free",
        effect: "Efek refine",
        refinelv: "Refine lv",
        addatk: "Add skill damage",
        totalSkill: "Total damage",
        dragonskill: "Skill dragon",
        bone: "Bone eroding inflammation",
        skill: "Skill pasif",
        ji: "Lv",
        lv4ji: "Skill lv 4 diaktifkan",
        lv8ji: "Skill lv 8 diaktifkan",
        lv12ji: "Skill lv 12 diaktifkan",
        suc: "Dragon diaktifkan",
        notactive: "Belum diaktifkan",
    },
    card: {
        buy: "Beli",
        unsell: "Turun",
        info: "Info",
        name: "Nama",
        grade: "Grade",
        camp: "Clan",
        profession: "Profesi",
        power: "Battle power",
        level: "Lv",
        star: "Star",
        num: "No",
        currentPrice: "Harga saat ini",
        totalamount: "Total transaksi",
        attributes: "Isi",
        blood: "Darah",
        attack: "ATK",
        defense: "Def",
        speed: "Speed",
        sattributes: "Spesial konten",
        rate: "Crit rate",
        control: "Kontrol",
        riot: "Resist Crit",
        free: "Immune",
        heal: "Heal",
        damage: "Damage+",
        spelldamage: "Magic damage",
        tospelldamage: "Magic immune",
        crit: "Crit damage",
        harm: "Anti kontrol",
        hit: "Hit",
        dodge: "Dodge",
        treatment: "Recover",
        pdamage: "Physic damage",
        damagefree: "Physic immune",
        heroSkill: "Skill hero",
        history: "Trade history",
        token: "Kartu token",
        time: "Waktu trade",
        from: "Pemenang saat ini",
        to: "Trader",
        price: "Harga trade",
        rice: "Putin Rice",
        type: "Tipe",
        zhudong: "Skill aktif",
        beidong: "Skill pasif",
        status: {
            operate: "Tidak dapat operasi",
            synchronizing: "Game sedang sync",
            chain: "Sedang chain",
            confirmation: "Sedang konfirmasi kejual",
            updategame: "Update game",
            chainfail: "Chain gagal",
            syncfail: "Sync gagal",
            gameAssets: "Aset game",
            walletAssets: "Aset dompet",
            auctioned: "Aset",
        },
        upgrade: "Skill upgrade",
        timeout: "Dalam lelang",
        modify: "Ubah harga",
        current_price: "Harga saat ini",
        new_price: "Silakan masukkan harga baru",
        modifySuccess: "Berubah dengan sukses"
    },
    hero: {
        name: {
            "21": "Frey",
            "22": "Niord",
            "23": "Freya",
            "31": "Niord",
            "32": "Freya",
            "33": "Geoffrey",
            "41": "Frey",
            "42": "Niord",
            "43": "Freya",
            "51": "Sur",
            "52": "Verdanti",
            "53": "Geoffrey",
            "54": "Linus",
            "55": "Ya Li",
            "61": "Amy",
            "62": "Bernas",
            "63": "Rauli",
            "64": "Andvari",
            "65": "Badr",
            "11001": "Amy",
            "11002": "Nana",
            "12001": "Shakert",
            "12002": "Cassano",
            "12003": "Hainier",
            "12004": "Zuda",
            "13001": "Tyr",
            "13002": "Momus",
            "13003": "Nashga",
            "13004": "Prometheus",
            "13005": "Rudolf",
            "14001": "Sur",
            "14002": "Phoenix",
            "14003": "Scarlett",
            "14004": "Hera",
            "14005": "Gormok",
            "15001": "Elena",
            "15002": "Hephaestus",
            "15003": "Hestia",
            "15004": "Rocky",
            "15005": "Candy witch",
            "15006": "Santa Claus",
            "21001": "Mannie",
            "21002": "Bernas",
            "22001": "Gerrard",
            "22002": "Scamanders",
            "22003": "Olaf",
            "23001": "Verdanti",
            "23002": "Gray Mane Werewolf",
            "23003": "Ares",
            "23004": "Ulmer",
            "23005": "Athena",
            "24001": "Bollier",
            "24002": "Charlene",
            "24003": "Frigg",
            "24004": "Poseidon",
            "24005": "Snowman",
            "25001": "Gaia",
            "25002": "Zeus",
            "25003": "Thorne",
            "25004": "Niord",
            "31001": "Rauli",
            "31002": "Satyros",
            "32001": "Pedro",
            "32002": "Brogan",
            "32003": "Cupid",
            "33001": "Carvo",
            "33002": "Ishtak",
            "33003": "Brienne",
            "33004": "Sieff",
            "33005": "Bracchi",
            "33006": "Geoffrey",
            "34001": "John",
            "34002": "Sindri",
            "34003": "Hermes",
            "34004": "Kashrek",
            "34005": "Melia",
            "35001": "Artemia",
            "35002": "Cadillan",
            "35003": "Frey",
            "35004": "Vidal",
            "41001": "Versatile",
            "41002": "Lucio",
            "42001": "Eden",
            "42002": "Modie",
            "42003": "Nash",
            "43001": "Dawa",
            "43002": "Apollo",
            "43003": "Scotty",
            "43004": "Venus",
            "44001": "Wukong",
            "44002": "Phoebe",
            "44003": "Linus",
            "44004": "Badr",
            "45001": "Leonidas",
            "45002": "Freya",
            "45003": "Odin",
            "45004": "Thor",
            "51001": "Knott",
            "51002": "Badal",
            "52001": "Andvari",
            "52002": "Silithus",
            "52003": "Valli",
            "53001": "Bartsa",
            "53002": "Ould",
            "53003": "Pandora",
            "53004": "Medusa",
            "53005": "Rainfield",
            "54001": "Ya Li",
            "54002": "Sabina",
            "54003": "Riga",
            "54004": "Erebus",
            "54005": "Raymond",
            "54006": "Pumpkin Ghost",
            "55001": "Domecia",
            "55002": "Hades",
            "55003": "Hecate",
            "55004": "Hella",
            "56": "Angel",
            "1503": "Man in black",
            "11901": "Hero Trainer",
            "12901": "Hero Trainer",
            "13901": "Hero Trainer",
            "14901": "Hero Trainer",
            "15901": "Hero Trainer",
            "21901": "Hero Trainer",
            "22901": "Hero Trainer",
            "23901": "Hero Trainer",
            "24901": "Hero Trainer",
            "25901": "Hero Trainer",
            "31901": "Hero Trainer",
            "32901": "Hero Trainer",
            "33901": "Hero Trainer",
            "34901": "Hero Trainer",
            "35901": "Hero Trainer",
            "41901": "Hero Trainer",
            "42901": "Hero Trainer",
            "43901": "Hero Trainer",
            "44901": "Hero Trainer",
            "45901": "Hero Trainer",
            "51901": "Hero Trainer",
            "52901": "Hero Trainer",
            "53901": "Hero Trainer",
            "54901": "Hero Trainer",
            "55901": "Hero Trainer",
        },
        status: {
            0: 'Dalam tim',
            1: 'Kejual',
            2: 'Terkunci',
            3: 'Konfirmasi link',
            4: 'Konfirmasi penjualan',
            5: 'Batal',
            51: 'Beli',
            6: 'Block mengoperasi',
        },
    },
    account: {
        setAccount: 'Selesaikan pengaturan akun Anda',
        setEmail: 'Setting email dan password',
        wallet: 'Dompet',
        address: 'Alamat',
        see: 'Lihat',
        tip: 'Game tidak online{type}',
        captip: "Geser ke kanan untuk isi puzzle",
        Activate: 'Aktifkan',
        paimai: "Lelang",
        receive: 'Ambil',
        Activateing: 'Sedang diaktifkan',
        hasActivate: 'Sudah diaktifkan',
        forsale: "Dijual",
        salePrice: 'Harga jual',
        salecard: "Jual kartu",
        saleprice: "Jumlah jual",
        saletip: `Seledai transaksi, platform akan kenakan {fee} dari jumlah penjualan sebagai biaya penanganan`,
        sell: "Jual",
        p_saleprice: "Masukkan harga lelang",
        saletip1: 'Jaringan transmisi saat ini adalah jaringan BSC',
        saletip2: `Setelah otorisasi, NFT Anda akan beredar di pasar sesuai aturan NFT111：
        <br />1. Siapapun dapat menawar setidaknya 1,11 kali lipat dari harga saat ini untuk transfer kepemilikan NFT。
        <br />2. Untuk setiap transaksi, 95% masuk ke pemegang, 3% masuk ke pemilik hak cipta, 1% masuk ke biaya transaksi, dan 1% masuk ke trader。
        <br />3. Timeout lock, setelah jangka waktu tertentu tanpa transaksi maka status lelang akan otomatis terkunci。`,
        shelf: "Turun",
        shelfor: "Diturunkan",
        shelgsuc: "Berhasil diturunkan",
        auctionsuc: "Penawaran telah berhasil, harap tunggu konfirmasi chain！",
        reAuctionsuc: "Berhasil menawar, harap tunggu konfirmasi chain！",
        depositsuc: "Deposit berhasil, harap tunggu konfirmasi chain！",
        claimsuc: 'Berhasil klaim',
        withdrawsuc: 'Telah berhasil diajukan, harap tunggu konfirmasi chain！',
        reAuctionfail: "NFT terkunci, harap tunggu konfirmasi chain！",
        activetips: 'Silahkan aktifkan akun terlebih dahulu',
        hasActivateTip: "Akun diaktifkan",
        activetip_p: `Apakah ingin aktifkan akun Anda dengan <span style='color:#56A1FF;'>{type}</span>`,
        Activateaccount: "Akun aktif",
        hasactive: 'Akun sudah aktif'
    },
    activity: {
        title: 'Event',
        view: 'Lihat event',
        more: 'Lebih',
        tip: {
            1: `Berhasil top up <span style="color:#67C23A">{amount} MDS</span>`,
            2: `Berhasil memilih <span style="color:#67C23A">{amount2} MDS</span>`,
            3: `Anda membeli hero <span style="color:#67C23A"{name}#{assets_id}</span> seharga <span style="color:#67C23A">{amount} BNB</span>`,
            4: `Anda menjual hero <span style="color:#67C23A">{name}#{assets_id}</span> seharga <span style="color:#67C23A">{amount} BNB</span>`,
            5: `Anda batalkan penjualan hero <span style="color:#67C23A">{name}#{assets_id}</span>`,
            6: `Hero Anda <span style="color:#67C23A">{name}#{assets_id}</span> dijual seharga <span style="color:#67C23A">{amount} BNB</span>`,
            7: `Penarikan Anda telah dikirim, total: <span style="color:#67C23A">{amount7} MDS</span>`,
            8: `Penarikan Anda telah ditolak, total: <span style="color:#67C23A">{amount8} MDS</span>`,
            9: `Anda telah aktifkan Dragon <span style="color:#67C23A">{name}#{assets_id}</span>`,
            11: `Summon hero <span style="color:#67C23A">{name}#{assets_id}</span> chain`,
            12: `Dragon diaktifkan <span style="color:#67C23A">{name}#{assets_id}</span> chain`,
            13: `Anda telah habis hero <span style="color:#67C23A">{name}#{assets_id}</span>`,
            14: `Penarikan Anda telah diajukan, total：<span style="color:#67C23A">{amount14} ENFT</span>`,
            15: `Penarikan Anda telah ditolak, total：<span style="color:#67C23A">{amount15} ENFT</span>`,
            16: `Anda berhasil top up <span style="color:#67C23A">{amount} ENFT</span>`,
            17: `Anda berhasil memilih <span style="color:#67C23A">{amount17} ENFT</span>`,
            18: `Anda telah ambil <span style="color:#67C23A">{amount}</span> kupon`,
            19: `Anda telah klaim hero <span style="color:#67C23A">{name}#{assets_id}</span> dari game ke dompet, dan biaya penanganannya adalah: <span style="color:#67C23A" >{fee}< /span>MDS`,
            20: `Anda telah deposit hero <span style="color:#67C23A">{name}#{assets_id}</span> ke dalam game dari dompet Anda`,
            21: `Anda telah menerima <span style="color:#67C23A">{name}#{assets_id}</span>, mohon tunggu konfirmasi pada chain`,
            22: `Anda telah berhasil memuat ulang!`,
            23: `Anda telah klaim <span style="color:#67C23A">{name}#{assets_id}</span>`,
            24: `Anda telah deposit dragon <span style="color:#67C23A">{name}#{assets_id}</span> ke dalam game dari dompet Anda`,
            25: `Anda telah menerima <span style="color:#67C23A">{name}#{assets_id}</span>, mohon tunggu konfirmasi pada chain`,
            26: `Anda telah klaim dragon <span style="color:#67C23A">{name}#{assets_id}</span> dari game ke dompet, dan biaya penanganannya adalah: <span style="color:#67C23A" >{fee}< /span>MDS`,
            30: `Anda lelang card <span style="color:#67C23A">{name}#{assets_id}</span> untuk <span style="color:#67C23A">{amount} BNB</span>`,
            31: `Card Anda <span style="color:#67C23A">{name}#{assets_id}</span> tidak memiliki tawaran, telah dibuka kuncinya dari perjanjian 111, harap ajukan`,
            32: `Card Anda <span style="color:#67C23A">{name}#{assets_id}</span> dijual seharga <span style="color:#67C23A">{two_amount} BNB</span>, Anda dapatkan Penghasilan: <span style="color:#67C23A">{amount} BNB</span>`,
            33: `Anda berhasil menawar card <span style="color:#67C23A">{name}#{assets_id}</span>, tawaran: <span style="color:#67C23A">{amount} BNB</span>`,
            34: `Card <span style="color:#67C23A">{name}#{assets_id}</span> yang Anda ikuti dalam penawaran telah mengajukan tawaran baru dan menerima dividen: <span style="color:#67C23A"> {amount} BNB</span>`,
            35: `Selamat! Anda berhasil menawar card <span style="color:#67C23A">{name}#{assets_id}</span>, tawaran terakhir: <span style="color:#67C23A">{amount} BNB</ span>`,
            36: `Penghasilan berhasil diterima, total：<span style="color:#67C23A">{amount} BNB</span>`,
            37: `Anda telah mengusulkan card <span style="color:#67C23A">{name}#{assets_id}</span> dari kontrak perjanjian 111 ke dompet`,

            50: `Anda berhasil top up <span style="color:#67C23A">{amount} HFH</span>`,
            54: `Anda berhasil memilih <span style="color:#67C23A">{amount54} HFH</span>`,
            51: `Penarikan Anda telah diajukan, total：<span style="color:#67C23A">{amount51} HFH</span>`,
            53: `Penarikan Anda telah ditolak, total: <span style="color:#67C23A">{amount53} HFH</span>`,

            52: `Berhasil aktifkan MDragon <span style="color:#67C23A">{name}#{assets_id}</span>`,

            55: `Akun telah diaktifkan，konsumsi META：<span style="color:#67C23A">{amount}</span>`,
            56: `Akun telah diaktifkan，konsumsi MDV：<span style="color:#67C23A">{amount}</span>`,
            57: `Berhasil top up <span style="color:#67C23A">{amount} MDV</span>`,

            60: `Anda telah dapat dragon，konsumsi <span style="color:#67C23A">{amount} MDS</span>`,
            62: `Anda telah mengubah harga lelang kartu <span style="color:#67C23A">{name}#{assets_id}</span> ke <span style="color:#67C23A">{amount} BNB</span>`,
	        63: `Berhasil tambahkan MDragon <span style="color:#67C23A">{name}#{assets_id}</span>`,
            '1-3': `Anda gunakan <span class="c-g">{amount} BNB</span> dari <span class="c-g">penjual#{two_userid}</span> beli <a href="{origin}/gun/equip/{index_id}" target="_blank" rel="noopener noreferrer">equip {nftname}#{tokenid}</a>`,
            '1-4': `Daftar untuk jual <a href="{origin}/gun/equip/{index_id}" target="_blank" rel="noopener noreferrer">equip {nftname}#{tokenid}</a>，jual dengan harga <span class="c-g">{amount} BNB</span>`,
            '1-5': `Batal <a href="{origin}/gun/equip/{index_id}" target="_blank" rel="noopener noreferrer">equip {nftname}#{tokenid}</a> menjual pending order`,
            '1-6': `Berlaku untuk alamat <span class="c-c">{two_address}</span> klaim <a href="{origin}/gun/equip/{index_id}" target="_blank" rel="noopener noreferrer">equip {nftname}#{tokenid}</a> konfirmasi transaksi`,
            '1-6-1': `Berlaku untuk alamat <span class="c-c">{two_address}</span> klaim <a href="{origin}/gun/equip/{index_id}" target="_blank" rel="noopener noreferrer">equip {nftname}#{tokenid}</a> transaksi berhasil，fee <span class="c-g">{fee} {coin}</span>`,
            '1-9': `Berhasil <span class="c-g">pembeli#{two_userid}</span> jual <a href="{origin}/gun/equip/{index_id}" target="_blank" rel="noopener noreferrer">equip {nftname}#{tokenid}</a>，Harga jual adalah <span class="c-g">{amount} BNB</span>，Terima pembayaran setelah dipotong biaya penanganan <span class="c-g">{two_amount} BNB</span>`,
            '1-11': `Berlaku untuk alamat <span class="c-c">{two_address}</span> simpan <a href="{origin}/gun/equip/{index_id}" target="_blank" rel="noopener noreferrer">equip {nftname}#{tokenid}</a> transaksi sedang konfirmasi`,
            '1-11-1': `Berlaku untuk alamat <span class="c-c">{two_address}</span> simpan <a href="{origin}/gun/equip/{index_id}" target="_blank" rel="noopener noreferrer">equip {nftname}#{tokenid}</a> berhasil transaksi`,

            '2-3': `Gunakan <span class="c-g">{amount} BNB</span> dari <span class="c-g">penjual#{two_userid}</span> beli <a href="{origin}/gun/pet/{index_id}" target="_blank" rel="noopener noreferrer">phantom god {nftname}#{tokenid}</a>`,
            '2-4': `Daftar untuk jual <a href="{origin}/gun/pet/{index_id}" target="_blank" rel="noopener noreferrer">phantom god {nftname}#{tokenid}</a>，harga jual <span class="c-g">{amount} BNB</span>`,
            '2-5': `Batal <a href="{origin}/gun/pet/{index_id}" target="_blank" rel="noopener noreferrer">phantom god {nftname}#{tokenid}</a> menjual pending order`,
            '2-6': `Berlaku untuk alamat <span class="c-c">{two_address}</span> klaim <a href="{origin}/gun/pet/{index_id}" target="_blank" rel="noopener noreferrer">phantom god {nftname}#{tokenid}</a> transaksi sedang konfirmasi`,
            '2-6-1': `Berlaku untuk alamat <span class="c-c">{two_address}</span> klaim <a href="{origin}/gun/pet/{index_id}" target="_blank" rel="noopener noreferrer">phantom god {nftname}#{tokenid}</a> berhasil transaksi，fee <span class="c-g">{fee} {coin}</span>`,
            '2-9': `Berhasil <span class="c-g">pembeli#{two_userid}</span> jual <a href="{origin}/gun/pet/{index_id}" target="_blank" rel="noopener noreferrer">phantom god {nftname}#{tokenid}</a>，harga jual <span class="c-g">{amount} BNB</span>，Terima pembayaran setelah dipotong biaya penanganan <span class="c-g">{two_amount} BNB</span>`,
            '2-11': `Berlaku untuk alamat <span class="c-c">{two_address}</span> simpan <a href="{origin}/gun/pet/{index_id}" target="_blank" rel="noopener noreferrer">phantom god {nftname}#{tokenid}</a> transaksi sedang konfirmasi`,
            '2-11-1': `Berlaku untuk alamat <span class="c-c">{two_address}</span> simpan <a href="{origin}/gun/pet/{index_id}" target="_blank" rel="noopener noreferrer">phantom god {nftname}#{tokenid}</a> transaksi berhasil`,

            '3-3': `Gunakan <span class="c-g">{amount} BNB</span> dari <span class="c-g">penjual#{two_userid}</span> beli <a href="{origin}/gun/lingshi/{index_id}" target="_blank" rel="noopener noreferrer">spirit stone {nftname}#{tokenid}</a>`,
            '3-4': `Daftar untuk jual <a href="{origin}/gun/lingshi/{index_id}" target="_blank" rel="noopener noreferrer">spirit stone {nftname}#{tokenid}</a>，harga jual <span class="c-g">{amount} BNB</span>`,
            '3-5': `Batal <a href="{origin}/gun/lingshi/{index_id}" target="_blank" rel="noopener noreferrer">spirit stone {nftname}#{tokenid}</a> menjual pending order`,
            '3-6': `Berlaku untuk alamat <span class="c-c">{two_address}</span> klaim <a href="{origin}/gun/lingshi/{index_id}" target="_blank" rel="noopener noreferrer">spirit stone {nftname}#{tokenid}</a> transaksi sedang konfirmasi`,
            '3-6-1': `Berlaku untuk alamat <span class="c-c">{two_address}</span> klaim <a href="{origin}/gun/lingshi/{index_id}" target="_blank" rel="noopener noreferrer">spirit stone {nftname}#{tokenid}</a> berhasil transaksi，fee <span class="c-g">{fee} {coin}</span>`,
            '3-9': `Berhasil <span class="c-g">pembeli#{two_userid}</span> jual <a href="{origin}/gun/lingshi/{index_id}" target="_blank" rel="noopener noreferrer">spirit stone {nftname}#{tokenid}</a>，harga jual <span class="c-g">{amount} BNB</span>，Terima pembayaran setelah dipotong biaya penanganan <span class="c-g">{two_amount} BNB</span>`,
            '3-11': `Berlaku untuk alamat <span class="c-c">{two_address}</span> simpan <a href="{origin}/gun/lingshi/{index_id}" target="_blank" rel="noopener noreferrer">spirit stone {nftname}#{tokenid}</a> transaksi sedang konfirmasi`,
            '3-11-1': `Berlaku untuk alamat <span class="c-c">{two_address}</span> simpan <a href="{origin}/gun/lingshi/{index_id}" target="_blank" rel="noopener noreferrer">spirit stone {nftname}#{tokenid}</a> transaksi berhasil`,
        },
    },
    legion: {
        title: 'Legiun saya',
        infoTooltip: 'Bagikan link undangan, undang teman untuk mengaktifkan akun, dan bergabung dengan pasukanmu. Saat pasukanmu membelanjakan MDS di MWars, termasuk melakukan aktivasi akun, summon, membeli item di market, kamu akan mendapatkan poin.',
        ruleDescription: 'Deskripsi aturan',
        level: 'Level',
        points: 'Poin',
        receive: 'Terima',
        canReceive: 'Bisa dikatakan',
        rules1: 'Bisa diklaim setiap 7 hari',
        rules2: 'Tanggal batas waktu: {finalTime}',
        viewDetails: 'Lihat rincian',
        myLegion: 'Legiun saya',
        pointsRankingThisMonth: 'Peringkat poin bulan ini',
        headerAccount: 'Akun',
        headerLevel: 'Level',
        integralRules: 'Aturan Integral',
        integralRules1: 'kondisi',
        integralRules2: 'integral',
        integralRules3: 'Prajurit',
        integralRules4: 'Tantang Boss dan selesaikan level 30',
        integralRules5: '6% belanja pribadi',
        integralRules6: 'Cavalier',
        integralRules7: 'Mengundang 6 atau lebih Warrior sebagai member',
        integralRules8: '8% belanja pribadi + 2% konsumsi anggota langsung',
        integralRules9: 'Nobleman',
        integralRules10: 'Mengundang 6 atau lebih Cavalier sebagai member',
        integralRules11: '10% belanja pribadi + 3% konsumsi anggota langsung',
        integralRules12: '',
        integralRules13: '',
        getMdsBox: {
            title: 'Terima MDS',
            amount: 'Jumlah',
            availablePoints: 'Poin yang tersedia',
            amountPlaceholder: 'Silakan masukkan jumlah MDS yang akan diterima',
            btnAll: 'Semua',
            btnConfirm: 'Konfirmasi',
            success: 'Ekstraksi berhasil'
        },
        levelName: {
            1: 'Soldier',
            2: 'Prajurit',
            3: 'Cavalier',
            4: 'Nobleman',
        },
        history: {
            title: 'Detail Poin',
            myPoints: 'Poin saya',
            receivedMds: 'diterima',
            tabPointAcquisition: 'Perolehan poin',
            tabMdsCollection: 'Koleksi MDS',
            titleMdsCollectionRecord: 'Catatan pengumpulan MDS',
            more: 'Lagi',
            typeMdsCollection: 'Koleksi MDS',
            type: {
                1: 'Hadiah rujukan',
                2: 'Hadiah penggunaan',
            }
        },
        ranks: {
            title: 'Peringkat',
            subtitle: 'Reward pool ditentukan berdasarkan data konsumsi bulan ini',
            rewardsLink: 'Hadiah Legiun bulan ini',
            rewardsUrl: 'https://metadragon.gitbook.io/id/events/mwars-legion',
            headerRanking: 'Peringkat',
            headerAccount: 'Akun',
            headerIntegral: 'Integral',
            myOwn: 'Saya',
        },
        rules: {
            title: 'Deskripsi aturan',
            1: 'I. Algoritma level dan poin pengguna',
            2: '1. Prajurit ☆',
            3: 'Ketentuan: Anggota baru yang aktif atau tidak aktif',
            4: 'Poin: Tidak ada',
            5: '2. Prajurit ★',
            6: 'Ketentuan: Lewati 30 level tantangan tuan',
            7: 'Poin: 6% untuk konsumsi Anda sendiri',
            8: '3. Ksatria★ ★',
            9: 'Ketentuan: Merekomendasikan langsung anggota yang memiliki lebih dari 6 prajurit',
            10: 'Poin: 8% dari konsumsi Anda sendiri + 2% dari konsumsi anggota yang dipromosikan langsung',
            11: '4. Jazz★ ★ ★',
            12: 'Ketentuan: Merekomendasikan langsung anggota dengan lebih dari 6 ksatria',
            13: 'Poin: 10% dari konsumsi Anda sendiri + 3% dari konsumsi anggota yang dipromosikan langsung',
            14: 'Cara perhitungannya adalah sebagai berikut: [Hubungan rekomendasi] A direkomendasikan B',
            15: '[Definisi]: Pengeluaran B adalah N, rasio imbalan pengeluaran B adalah B1, rasio imbalan rekomendasi adalah B2, rasio imbalan pembelanjaan A adalah A1, rasio imbalan rekomendasi adalah A2, maka: B poin = N*B1, A poin = N *A2, tidak ada imbalan untuk orang lain.',
            16: 'Misalnya: A (★ ★ ★) → B (★ ★), B berharga 100MDS.',
            17: 'Maka, B1=8%, A2=3%',
            18: 'Perhitungannya adalah sebagai berikut:',
            19: 'Poin B meningkat 100*8%=8',
            20: 'Poin meningkat 100*3%=3',
            21: 'II. Terima MDS',
            22: '1. Setelah pemain di legiun melakukan pembelian, mereka dan atasan langsungnya akan langsung menerima poin.',
            23: '2. Rasio penukaran: 1 poin dapat menerima 1MDS.',
            24: '3. Daftar peringkat',
            25: 'Peringkat 100 poin bulanan teratas adalah dari tanggal 1 hingga akhir setiap bulan.',
            26: 'Ekspor tabel data dan distribusikan secara manual melalui airdrop pada rantai.',
        }
    },
    share: {
        my: 'Link undangan',
        link: 'Share link',
        child: 'Undangan saya',
        userid: 'User Id',
        join: 'Waktu daftar',
        state: 'Status',
        state0: 'Belum aktif',
        state1: 'Sudah aktif',
        invite: 'Kode undangan',
        qrbtn: 'Share kode QR',
        qrtitle: 'Undang kode QR',
        qrtips: 'Scan atau simpan gambar untuk dibagi',
        nodata: "Tidak ada catatan bagi",
        tips: 'Jumlah：{people1}orang，Diaktifkan：{actived}orang，dapatkan kupon：{people2}',
        tipsShort: 'Jumlah：{people1}orang，Diaktifkan：{actived}orang',
        tips2: 'Tidak ada kupon yang bisa diklaim',
        tips3: '是否將召喚券領取到{account}？領取數量：{num}',
        tiptitle: "Klaim",
        unget: "Belum klaim kupon",
        sharetip: "Undang anggota untuk mendaftar dan mengaktifkan akun mereka, dan Anda akan menerima kupon pemanggilan premium sebagai hadiah. Semakin banyak Anda mengundang, semakin banyak yang akan Anda terima!",
        getsuc: "Anda telah klaim{num} kupon summon",
        myid: "ID Anda",
        activetime: "Waktu aktif"
    },
    setting: {
        title: 'Pengaturan Akun',
    },
    address: {
        title: 'Set alamat',
        address: 'Alamat dompet',
        p_address: 'Masukan alamat dompet',
        save: 'Ganti alamat',
    },
    activate: {
        title: 'Aktifkan hero-',
        name: 'Nama',
        btn: 'Konfirmasi aktif',
        ok: 'Aktif berhasil',
        go: 'Aktif',
    },
    nickname: {
        title: 'kami harus memanggilmu apa？',
        content: 'Silakan masukan nama。 Bisa diubah。',
        p_nickname: 'Masukan nama',
        p_nicknamemax: 'Panjang panggilan tidak dapat melebihi 10 digit',
        ok: 'Nama berhasil diubah',
        unchanged: 'Nama tidak berubah',
        name: 'Nama',
        save: 'Simpan perubahan',
        tip: 'Maksimal 10 Karakter',
    },
    email: {
        title: 'Bind email',
        title1: 'Setting email',
        email: 'Email',
        p_email: 'Silakan masukan akun email',
        err_email: 'Email salah',
        save: 'Ganti email',
        noBind: 'Belum bind email',
        google: 'Captcha',
        p_set: 'Silakan bind email',
        limit: 'Kata melebihi limit',
        emailcode: "Kode verifikasi email",
        send: "Kirim",
        p_emailcode: "Silakan masukan kode verifikasi email",
        modiEmailsuc: "Berhasil ubah email",
        modisuc: "Berhasil ubah"
    },
    code: {
        title: 'Verifikasi email anda',
        content: 'Kami telah kirimkan kode verifikasi melalui email <span class="c-f">{email}</span> Harap verifikasi sebelum masuk dengan email ini',
        code: 'Kode verifikasi',
        tip: 'NONAKTIF akan batalkan pengaturan',
        p_code: 'Masukan kode verifikasi',
        w_code: 'Login password',
        pwd_code: 'Masukan password',
        pwd_newcode: 'Masukan password baru',
    },
    pass: {
        title: 'Atur password baru',
        pass: 'Kata sandi',
        oldPass: 'Password lama',
        newPass: 'Password baru',
        rePass: 'Reser password',
        conPass: "Konfirmasi password",
        p_oldPass: 'Masukan password lama',
        p_pass: 'Masukan password',
        p_conPass: "Masukan password baru",
        p_rePass: 'Masukan ulang password',
        err_rePass: 'Password ulang salah',
        save: 'Ganti password',
        savefund: 'Ganti password fund',
        tip: 'Password anda sudah direset<a href="{origin}/login" target="_blank" rel="noopener noreferrer">Login dengan password baru</a>',
        code: "Kode verifikasi",
        confirmpas: "Konfirmasi password baru",
        tip2: "Password anda sudah direset",
        tip3: "Login dengan password baru"
    },
    fund: {
        fund: 'Fund password',
        title: 'Setting password binding baru',
        loginPass: 'Login password',
        newPass: 'Input password baru',
        rePass: 'Konfirmasi password',
        p_loginPass: 'Masukan kode verifikasi email',
        p_pass: 'Masukan password bind',
        save: 'Setting password',
        savefund: 'Set binding password',
        tip: 'Password telah diupdate',
        p_set: 'Setting password fund',
        remian: 'Kode verifikasi',
        bindE: 'Bind email',
        p_newpass: 'Masukan password baru bind',
    },
    trans: {
        number: 'No',
        able: 'Saldo tersedia',
        total: 'Semua',
        address: 'Alamat',
        default: 'Default',
        arrival: 'Transfer',
        fee: 'fee',
        p_number: 'Masukan no',
        p_numberda: 'Kuantitas harus lebih besar dari nol',
        p_address: 'Masukan alamat',
        err_able: 'Saldo tidak cukup',
        err_min: 'Min deposit',
        ok: 'Transaksi berhasil',
        to: 'Transfer ke event saya',
        name: 'Nama',
        sellAt: 'Harga jual',
        WarningTip: "Harap jangan tutup jendela sebelum transaksi selesai, jika tidak, aset Anda mungkin akan mengalami kerugian。",
        WarningTip1: "Akun peran masing-masing server pada platform ini tidak bergantung satu sama lain, harap konfirmasi sebelum melakukan deposit；",
        p_sell: 'Masukan harga penjualan',
        confirmTip: 'Equip sedang dikonfirmasi dan sementara tidak dapat didagangkan ',
        err_tokenid: 'Blokiran dikonfirmasi, dan transaksi tidak didukung saat ini, refresh halaman dan coba lagi！',
        min_number: 'Jumlah min penarikan',
        min_quantity: `Jumlah min pengambilan {name}`,
        holder: 'Holder',
        claimTip: 'NFT akan ditransfer dari game ke dompet Anda',
        p_deposit: 'Silakan aktifkan dulu MDragon',
        depositTip: 'NFT akan ditransfer dari dompet Anda untuk dalam game',
        withdrawTip: 'NFT akan ditarik dari kontrak NFT111 dan ditransfer ke dompet Anda',
    },
    guide: {
        step: {
            1: 'Buat dompet Metamask',
            2: 'Buat akun',
            3: 'Download game',
            4: 'BUat hero',
        },
        0: {
            title: 'Memulai Raiders of Dragon Valley',
            content: `Raiders of the Dragon Valley adalah game pertempuran role-playing terbaru yang seru dan menyenangkan.Pemain dapat merasakan efek karakter 3D yang baru di dalam game, memungkinkan Anda dengan mudah memulai pengalaman pertempuran bawah tanah terbaru di dalam game. Download sekarang！`,
        },
        1: {
            title: 'Buat dompet Metamask Anda',
            content: `Dompet Metamask adalah pintu pendaftaran ke game kami`,
            title1: 'Download dan setting',
            p1: 'Perluas program',
            chrome: 'Chrome',
            firefox: 'Firefox',
            title2: 'Buat alamat BSC',
            p2: 'Untuk apa dompet Metamask？',
            answer1: 'Gunakan alamat Metamask BSC untuk masuk game Dragon Valley',
            answer2: 'Bind email sesuai petunjuk halaman',
        },
        2: {
            title: 'Setting akun',
            content: `Dompet Metamask adalah pintu pendaftaran ke game kami`,
            title1: 'Buat akun',
            p1: 'Gunakan alamat metamask BSC sebagai akun',
            p2: 'Login',
            p3: 'Mall Dragon Valley Raiders',
            answer1: 'Setor RDVS ke alamat ini, koin ini akan digunakan untuk membayar hero',
        },
        3: {
            title: 'Download game',
            title1: 'Browser',
            title2: 'Terminal',
            and: 'Android',
            ios: 'Ios',
        },
        4: {
            title: 'Buat hero',
            title1: 'Gabungkan hero dalam game',
            p1: 'Masuk ke game dan mulai buat karakter hero',
            answer1: 'Masuk game, pilih karakter hero, dan bayar fee untuk selesaikan pembuatan karakter. Semua persiapan sudah selesai, ayo mulai game',
        },
    },
    tip: {
        noEmail: 'Silakan bind email dan setting password'
    },
}
